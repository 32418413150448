<template>
  <v-navigation-drawer
    app
    fixed
    mini-variant-width="60"
    :width="450"
    :mini-variant="!this.$route.meta.expandedDrawer"
    color="white"
    mobile-breakpoint="0"
    permanent
    touchless
    stateless
    @transitionend="transitioning = false"
    floating
  >
    <v-row style="overscroll-behavior: contain" no-gutters class="fill-height flex-nowrap">
      <v-navigation-drawer fixed floating dark color="#001426" mini-variant mini-variant-width="60" permanent stateless>
        <v-tooltip v-if="orgSwitcherOptions.length > 1" right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              class="py-1"
              v-bind="attrs"
              v-on="on"
              v-model="isDisplayingEnterprise"
              :to="{ name: 'Enterprise' }"
            >
              <v-list-item-icon>
                <v-img :src="require('@/assets/Arrowhealth-bug-RGB.png')"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Enterprise</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Enterprise Statistics</span>
        </v-tooltip>

        <v-list-item v-if="orgSwitcherOptions.length === 1" @click="navigateHome()">
          <v-list-item-icon>
            <v-img :src="require('@/assets/Arrowhealth-bug-RGB.png')"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list nav dense>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingDashboard" :to="{ name: 'Inbox' }">
                <v-list-item-icon>
                  <v-icon>mdi-view-dashboard</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Inbox</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>Inbox</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingMessaging" :to="{ name: 'Conversations' }">
                <v-list-item-icon>
                  <v-badge :value="unreadConvosCount" :content="unreadConvosCount" color="primary" overlap>
                    <v-icon>mdi-message-reply-text</v-icon>
                  </v-badge>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Conversations</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>Conversations</span>
          </v-tooltip>
          <app-drawer-user-navigation-list v-if="false" />
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingAccount" :to="accountRoute">
                <v-list-item-icon>
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            {{ user.firstName }} {{ user.lastName }}
          </v-tooltip>
          <v-fade-transition>
            <v-tooltip v-if="org" right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingOrg" exact :to="orgRoute">
                  <v-list-item-icon>
                    <v-icon>mdi-domain</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              {{ org.name }}
            </v-tooltip>
          </v-fade-transition>
          <v-fade-transition>
            <v-tooltip v-if="org" right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingEnrollment" :to="enrollmentRoute">
                  <v-list-item-icon>
                    <v-icon class="rotate">mdi-account-plus</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Enrollment</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              Enrollment
            </v-tooltip>
          </v-fade-transition>
          <v-fade-transition>
            <v-tooltip v-if="org" right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingDeviceManagement" :to="deviceRoute">
                  <v-list-item-icon>
                    <v-icon class="rotate">mdi-devices</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Devices</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              Devices
            </v-tooltip>
          </v-fade-transition>
          <v-fade-transition>
            <v-tooltip v-if="org" right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" @click="openNewBillingPlatform()" v-model="isDisplayingReports">
                  <v-list-item-icon>
                    <v-icon>mdi-google-spreadsheet</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Reports</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              Go to reports
            </v-tooltip>
          </v-fade-transition>
        </v-list>
        <template v-slot:append>
          <v-tooltip v-if="org" right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" @click="$emit('turnOffEnhanced')" v-model="isDisplayingReports">
                <v-list-item-icon>
                  <v-icon>mdi-chart-donut</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Enhanced View</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            Toggle Enhanced View
          </v-tooltip>
        </template>
      </v-navigation-drawer>
      <v-col
        id="inboxScrollbox"
        style="overflow-y: scroll; overflow-x: hidden; background-color: #eff2f5; padding-left: 60px"
      >
        <div v-if="!transitioning">
          <v-card
            style="
              position: sticky;
              top: 0;
              z-index: 100;
              backdrop-filter: blur(2px);
              border-right: 1px solid transparent;
            "
            height="64"
            flat
            tile
            color="#eff2f5cc"
            class="flex"
          >
            <v-row align="center" no-gutters class="fill-height px-3 flex">
              <v-fade-transition hide-on-leave>
                <v-select
                  v-if="orgSwitcherOptions.length > 0 && !searchFocused"
                  style="font-weight: bold"
                  hide-details
                  solo
                  flat
                  background-color="transparent"
                  placeholder="ORG SWITCHER"
                  v-model="orgSwitcherSelection"
                  :items="orgSwitcherOptions"
                  @change="switchOrg"
                  return-object
                >
                  <template v-slot:selection="{ item }">
                    <v-row align="center">
                      <v-avatar class="white--text mr-2" size="32" color="primary">
                        <span style="font-weight: bold; font-size: 14px">
                          {{ item.name.split(' ')[0].charAt(0)
                          }}<span v-if="item.name.split(' ')[1]">{{ item.name.split(' ')[1].charAt(0) }}</span>
                        </span>
                      </v-avatar>
                      <span class="text-truncate" style="width: 270px">{{ item.name }}</span>
                    </v-row>
                  </template>
                  <template v-slot:item="{ item }">
                    <span>{{ item.name }}</span>
                  </template>
                </v-select>
              </v-fade-transition>
            </v-row>
          </v-card>

          <v-fade-transition hide-on-leave>
            <div style="height: 56px">
              <v-row class="mx-3 fill-height" align="start" no-gutters>
                <global-patient-search-simplified
                  @blurred="searchFocused = false"
                  :orgsDict="fullOrgsDict"
                  @searchResultPatient="searchResultPatient"
                />
              </v-row>
            </div>
          </v-fade-transition>
          <v-slide-y-reverse-transition>
            <inbox-patient-list v-if="!patientsLoading" headerColor="#EFF2F5" @clicked="handleClick" />
          </v-slide-y-reverse-transition>
        </div>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>
<style>
.v-card--link:before {
  background: none;
}
</style>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { getOrgs } from '@/shared-ui/store/org'
import GlobalPatientSearchSimplified from '@/components/rpminbox/GlobalPatientSearchSimplified.vue'
import InboxPatientList from '@/components/rpminbox/InboxPatientList.vue'

export default {
  components: {
    GlobalPatientSearchSimplified,
    InboxPatientList,
  },
  props: {},
  data() {
    return {
      patientSearchText: null,
      selectedPatients: [],
      fullOrgsDict: {},
      transitioning: false,
      searchFocused: false,
    }
  },
  watch: {
    $route: {
      handler: function (ti, tb) {
        let currentDrawerOpen = tb.meta.expandedDrawer === true
        let destinationDrawerOpen = ti.meta.expandedDrawer === true

        if (currentDrawerOpen !== destinationDrawerOpen) {
          this.transitioning = true
        }
      },
      deep: true,
      immediate: true,
    },
    transitioning: {
      handler: function (val) {
        this.$emit('transitioning', val)
      },
    },
    // enrolledPatients: {
    //   handler: async function (val, oldVal) {
    //     if (val.length > 0 && (!oldVal || val.length !== oldVal.length)) {
    //       val.forEach(async patient => {
    //         await this.convertLegacyReviewNotesToFirebaseReviewNotes(patient)
    //       })
    //     }
    //   },

    //   deep: true,
    //   immediate: true,
    // },
    orgSwitcherOptions() {
      this.orgSwitcherSelection = this.orgSwitcherOptions.find(opt => {
        return opt.id === this.selectedOrgId
      })
      this.orgSwitcherSelection = this.orgSwitcherSelection || this.orgSwitcherOptions[0]
      this.loadOrg(this.orgSwitcherSelection.id)
    },
    // providerReviewNotifications: {
    //   handler: function (newData) {
    //     console.log('new provider review notifications', newData)
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    // careTeamReviewNotifications: {
    //   handler: function (newData) {
    //     console.log('new care tean review notifications', newData)
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    // mentions: {
    //   handler: function (newData) {
    //     console.log('new mentions notifications', newData)
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  methods: {
    ...mapActions('org', ['loadOrg']),
    ...mapMutations('auth', ['setSelectedOrgId']),
    ...mapMutations('inbox', ['setPatientList', 'setOrgNotifications']),
    ...mapActions('patient', ['convertLegacyReviewNotesToFirebaseReviewNotes']),

    searchResultPatient(patient) {
      if (this.org.id === patient.org.id) {
        this.selectedCategory = 2
        this.mode = 3
        this.$router.push({ name: 'PatientFeed', params: { id: patient.id } })
      } else {
        this.orgSwitcherSelection = this.orgSwitcherOptions.find(opt => {
          return opt.id === patient.org.id
        })

        this.setOrgNotifications(null)
        this.setSelectedOrgId(this.orgSwitcherSelection.id)

        this.loadOrg(this.orgSwitcherSelection.id).then(() => {
          this.selectedCategory = 2
          this.mode = 3
          this.$router.push({ name: 'PatientFeed', params: { id: patient.id } })
        })
      }
    },
    generateNoteForDisplay(patientObject, notification) {
      if (patientObject.rpm.notes.length > 0) {
        let timestampOfCorrectNote = notification.ts
        let latestNote = patientObject.rpm.notes[0]

        if (latestNote.ts !== timestampOfCorrectNote) {
          patientObject.rpm.notes.forEach(note => {
            if (note.ts === timestampOfCorrectNote) {
              latestNote = note
            }
          })
        }

        if (notification.comment) {
          if (latestNote.comments.length > 0) {
            latestNote = latestNote.comments[0]
          }
        }
        let displayObject = { text: 'Note', author: 'Author', date: 'date' }

        if (this.orgUserDictionary[latestNote.userId]) {
          displayObject.author = this.orgUserDictionary[latestNote.userId].name
        }
        displayObject.text = latestNote.text
        displayObject.date = notification.notificationTimestamp
        return displayObject
      }

      return null
    },
    openNewBillingPlatform() {
      this.child = window.open('https://partners.novorpm.com', 'null', null)
    },
    switchOrg() {
      this.navigateHome()
      this.setOrgNotifications(null)
      this.setSelectedOrgId(this.orgSwitcherSelection.id)
      this.loadOrg(this.orgSwitcherSelection.id)
    },
    navigateHome() {
      this.selectedPatients = []
      if (this.$route.name !== 'Inbox') {
        this.$router.push({ name: 'Inbox' })
      }
    },
    handleClick(patient) {
      if (this.$route.params.id === patient.id) {
        this.$router.push({ name: 'Inbox' })
      } else {
        this.$router.push({ name: 'PatientFeed', params: { id: patient.id } })
      }
    },
  },
  computed: {
    ...mapState('auth', ['user', 'selectedOrgId']),
    ...mapState('org', [
      'org',
      'patientsLoading',
      'enrolledPatients',
      'unreadConvosCount',
      'unenrolledPatients',
      'users',
    ]),
    ...mapState('org', ['providerReviewNotifications', 'careTeamReviewNotifications', 'mentions']),
    ...mapState('patient', ['patient']),
    orgUserDictionary() {
      const dict = {}
      if (this.users && this.users.length > 0) {
        this.users.forEach(user => {
          dict[user.id] = { name: 'Unknown', initials: 'UK' }
          if (user.firstName && user.lastName) {
            dict[user.id].name = user.firstName + ' ' + user.lastName
            dict[user.id].initials = user.firstName.charAt(0) + user.lastName.charAt(0)
          }
        })
      }
      return dict
    },
    expandedDrawer() {
      if ('Inbox' === this.$route.name || 'PatientFeed' === this.$route.name) {
        return true
      }
      return false
    },
    isDisplayingReports() {
      return 'Reports' === this.$route.name
    },
    isDisplayingEnterprise() {
      return 'Enterprise' === this.$route.name
    },
    isDisplayingAccount() {
      return 'Account' === this.$route.name
    },
    isDisplayingDevices() {
      return 'Enrollment' === this.$route.name
    },
    isDisplayingEnrollment() {
      return 'Device Management' === this.$route.name
    },
    isDisplayingOrg() {
      return 'Organization' === this.$route.name
    },
    accountRoute() {
      return { name: 'Account', params: { id: this.user.id } }
    },
    orgRoute() {
      return { name: 'Organization', params: { id: this.org.id } }
    },
    enrollmentRoute() {
      return { name: 'Enrollment', params: { id: this.org.id } }
    },
    deviceRoute() {
      return { name: 'Device Management', params: { id: this.org.id } }
    },
    isDisplayingAlerts() {
      return 'Alerts' === this.$route.name
    },
    isDisplayingInbox() {
      return 'Inbox' === this.$route.name
    },
    isDisplayingActivity() {
      return 'Engagement' === this.$route.name
    },
    isDisplayingDeviceManagement() {
      return 'Device Management' === this.$route.name
    },
    isDisplayingDashboard() {
      return 'Inbox' === this.$route.name
    },
    isDisplayingMessaging() {
      return 'Messaging' === this.$route.name
    },
    isDisplayingConversations() {
      return 'Conversations' === this.$route.name
    },
    notesForMentions() {
      let notifications = []
      if (this.enrolledPatients.length > 0) {
        this.mentions.forEach(notification => {
          let patient = this.enrolledPatients.find(pt => pt.id === notification.patient)
          if (patient) {
            let notificationObject = {}
            notificationObject.patient = patient

            notificationObject.firstName = patient.firstName
            notificationObject.lastName = patient.lastName
            notificationObject.org = { patientId: patient.org.patientId }
            notificationObject.notificationTimestamp = notification.notificationTimestamp
            // get the text of the note

            notificationObject.noteForDisplay = this.generateNoteForDisplay(patient, notification)
            notificationObject.firebaseNotification = notification

            notifications.push(notificationObject)
          }
        })
      }

      return notifications
    },
    notesForProviderReview() {
      let notifications = []
      if (this.enrolledPatients.length > 0) {
        this.providerReviewNotifications.forEach(notification => {
          let patient = this.enrolledPatients.find(pt => pt.id === notification.patient)
          if (patient) {
            console.log('generating note for patient', patient.lastUpdatedLocally)
            let notificationObject = {}
            notificationObject.patient = patient

            notificationObject.firstName = patient.firstName
            notificationObject.lastName = patient.lastName
            notificationObject.org = { patientId: patient.org.patientId }
            notificationObject.notificationTimestamp = notification.notificationTimestamp
            // get the text of the note

            notificationObject.noteForDisplay = this.generateNoteForDisplay(patient, notification)
            notificationObject.firebaseNotification = notification

            notifications.push(notificationObject)
          }
        })
      }
      return notifications
    },

    notesForCareTeamReview() {
      let notifications = []
      if (this.enrolledPatients.length > 0) {
        this.careTeamReviewNotifications.forEach(notification => {
          let patient = this.enrolledPatients.find(pt => pt.id === notification.patient)

          let notificationObject = {}
          notificationObject.patient = patient

          notificationObject.firstName = patient.firstName
          notificationObject.lastName = patient.lastName
          notificationObject.org = { patientId: patient.org.patientId }
          notificationObject.notificationTimestamp = notification.notificationTimestamp
          // get the text of the note

          notificationObject.noteForDisplay = this.generateNoteForDisplay(patient, notification)
          notificationObject.firebaseNotification = notification

          notifications.push(notificationObject)
        })
      }
      return notifications
    },
    newWithin7Days() {
      return this.enrolledPatients.filter(function (e) {
        const thirtyDays = new Date()
        thirtyDays.setDate(thirtyDays.getDate() - 90)
        thirtyDays.setHours(0, 0, 0, 0)

        return (
          e.rpm &&
          e.rpm.enrollment &&
          e.rpm.enrollment.start &&
          Date.parse(e.rpm.enrollment.start) > thirtyDays.getTime() &&
          !e.tags.includes('welcomed')
        )
      })
    },
    careTeamStarred() {
      return this.enrolledPatients.filter(function (e) {
        return e.tags && e.tags.includes('Care Team Starred')
      })
    },
    navigatorReview() {
      return this.notesForCareTeamReview
    },
    mentionsReview() {
      return this.notesForMentions
    },
    zeroTime() {
      return this.enrolledPatients.filter(function (e) {
        //listPatient.rpm.qhpTime.millisecondsThisPeriod
        return !e.rpm.qhpTime || !e.rpm.qhpTime.millisecondsThisPeriod || e.rpm.qhpTime.millisecondsThisPeriod === 0
      })
    },
    under20() {
      return this.enrolledPatients
        .filter(function (e) {
          return (
            e.rpm.qhpTime &&
            e.rpm.qhpTime.millisecondsThisPeriod &&
            e.rpm.qhpTime.millisecondsThisPeriod > 0 &&
            e.rpm.qhpTime.millisecondsThisPeriod < 1200000
          )
        })
        .sort((a, b) => b.rpm.qhpTime.millisecondsThisPeriod - a.rpm.qhpTime.millisecondsThisPeriod)
    },
    under40() {
      return this.enrolledPatients
        .filter(function (e) {
          return (
            e.rpm.qhpTime &&
            e.rpm.qhpTime.millisecondsThisPeriod &&
            e.rpm.qhpTime.millisecondsThisPeriod >= 1200000 &&
            e.rpm.qhpTime.millisecondsThisPeriod < 2400000
          )
        })
        .sort((a, b) => b.rpm.qhpTime.millisecondsThisPeriod - a.rpm.qhpTime.millisecondsThisPeriod)
    },
    moreThan40() {
      return this.enrolledPatients
        .filter(function (e) {
          return (
            e.rpm.qhpTime && e.rpm.qhpTime.millisecondsThisPeriod && e.rpm.qhpTime.millisecondsThisPeriod >= 2400000
          )
        })
        .sort((a, b) => b.rpm.qhpTime.millisecondsThisPeriod - a.rpm.qhpTime.millisecondsThisPeriod)
    },
    archivedPatients() {
      return this.unenrolledPatients
    },
    unseenAlerts() {
      return this.enrolledPatients.filter(function (e) {
        return e.unseenAlert
      })
    },
    generalBreaches() {
      return this.enrolledPatients.filter(function (e) {
        return e.alert && !e.unseenAlert
      })
    },
    // orgNotificationDictionary() {
    //   // org notifications should have three things in them.
    //   //new patient reminder
    //   // threshold breach
    //   // care team review
    //   // mentions
    //   let orgNotifications = {}
    //   this.newWithin7Days.forEach(patient => {
    //     if (!orgNotifications[patient.id]) {
    //       orgNotifications[patient.id] = []
    //     }
    //     let notification = {
    //       title: 'New Patient',
    //       text: patient.firstName + ' has been enrolled recently but may need initial outreach.',
    //       timestamp: Date.parse(patient.rpm.enrollment.start),
    //       type: 'newPatient',
    //     }
    //     orgNotifications[patient.id].push(notification)
    //   })

    //   this.unseenAlerts.forEach(patient => {
    //     if (!orgNotifications[patient.id]) {
    //       orgNotifications[patient.id] = []
    //     }

    //     let alertSentence = ''
    //     let alertsForClearing = []
    //     patient.rpm.newAlertsArray.forEach(alert => {
    //       const reading = patient.rpm.lastReadings[alert]
    //       alertsForClearing.push(reading.id)
    //       alertSentence =
    //         alertSentence + reading.longName + ' was ' + reading.displayValue + ' on ' + reading.readableDate + '. '
    //     })

    //     let notification = {
    //       title: 'Threshold Breach',
    //       text: patient.firstName + "'s " + alertSentence,
    //       timestamp: Date.parse(patient.rpm.enrollment.start),
    //       type: 'thresholdBreach',
    //       payload: alertsForClearing,
    //       notePrompt: patient.firstName + "'s " + alertSentence,
    //     }
    //     orgNotifications[patient.id].push(notification)
    //   })

    //   this.notesForCareTeamReview.forEach(notificationObject => {
    //     if (!orgNotifications[notificationObject.patient.id]) {
    //       orgNotifications[notificationObject.patient.id] = []
    //     }

    //     let truncatedText = ''

    //     if (notificationObject.noteForDisplay.text.length < 149) {
    //       truncatedText = notificationObject.noteForDisplay.text
    //     } else {
    //       truncatedText = notificationObject.noteForDisplay.text.slice(0, 150)
    //     }
    //     let notification = {
    //       title: 'Care Team Review',
    //       text: notificationObject.noteForDisplay.author + ': ' + truncatedText,
    //       timestamp: new Date(),
    //       type: 'careTeamReview',
    //       notificationForClearing: notificationObject.firebaseNotification,
    //     }
    //     orgNotifications[notificationObject.patient.id].push(notification)
    //   })

    //   if (this.org && this.org.id && this.user && this.user.firstName) {
    //     this.notesForMentions.forEach(notificationObject => {
    //       if (!orgNotifications[notificationObject.patient.id]) {
    //         orgNotifications[notificationObject.patient.id] = []
    //       }

    //       let truncatedText = ''

    //       if (notificationObject.noteForDisplay.text.length < 149) {
    //         truncatedText = notificationObject.noteForDisplay.text
    //       } else {
    //         truncatedText = notificationObject.noteForDisplay.text.slice(0, 150)
    //       }
    //       let notification = {
    //         title: this.user.firstName + ' ' + this.user.lastName,
    //         text: notificationObject.noteForDisplay.author + ': ' + truncatedText,
    //         timestamp: new Date(),
    //         type: '@mention',
    //         notificationForClearing: notificationObject.firebaseNotification,
    //       }
    //       orgNotifications[notificationObject.patient.id].push(notification)
    //     })
    //   }

    //   return orgNotifications
    // },
    orgSwitcherOptions() {
      const options = []
      for (const o of this.user.orgs) {
        if (!this.fullOrgsDict[o.orgId]) continue
        options.push({
          name: this.fullOrgsDict[o.orgId].name,
          id: o.orgId,
          value: this.fullOrgsDict[o.orgId].name,
        })
      }
      options.sort((a, b) => a.name.localeCompare(b.name))
      return options
    },
  },
  async beforeCreate() {
    const fullOrgsDict = {}
    for (const org of await getOrgs()) {
      fullOrgsDict[org.id] = org
    }
    this.fullOrgsDict = fullOrgsDict
  },
}
</script>
