<template>
  <v-row style="width: 100%; " no-gutters justify="center" align="center">
    <v-card flat color="transparent" width="200">
      <v-card color="transparent" flat :height="shrink ? '190' : '38vh'" class="flex">
        <v-row align="end" justify="center" class="fill-height" no-gutters>
          <v-img max-width="200" :src="image" />
        </v-row>
      </v-card>
      <v-row
        class="pb-2"
        justify="center"
        style="text-align: center; font-weight:bold; font-size: 16px; opacity: 0.8"
        no-gutters
      >
        {{ title }}
      </v-row>
      <v-row class="px-8" justify="center" style="text-align: center; font-weight:300; font-size: 14px;" no-gutters>
        {{ detail }}
      </v-row>
      <v-row class="py-6" no-gutters align="center" justify="center">
        <slot> </slot>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    image: {
      type: String,
      default: require('@/assets/Done.svg'),
    },
    title: {
      type: String,
      default: 'Page',
    },
    detail: {
      type: String,
      default: 'This is what you can expect to find on this page.',
    },
    shrink: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
})
</script>
