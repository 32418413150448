<template>
  <v-row style="padding-left:10px;" class="pr-3" no-gutters align="center">
    <slot />
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-1"
          depressed
          v-bind="attrs"
          v-on="on"
          @click="downloadPtListCSV(selectedPatients, 'Selected Patients')"
          icon
          :disabled="selectedPatients.length === 0"
        >
          <v-icon size="23">mdi-cloud-download-outline</v-icon>
        </v-btn>
      </template>
      Export Patient List
    </v-tooltip>

    <MassTextMenu :alwaysOn="true" :selectedPatients="selectedPatients" ref="massText" />
    <patient-tag-manager-dialog :alwaysOn="true" :selectedPatients="selectedPatients" ref="tagManagerDialog" />
    <v-spacer />
    <v-fade-transition hide-on-leave origin="start">
      <v-chip label v-show="selectedPatients.length > 0" class="ml-2" color="primary" small>
        <span>{{ selectedPatients.length }} Selected</span>
      </v-chip>
    </v-fade-transition>
  </v-row>
</template>

<script>
import MassTextMenu from '@/components/dialogs/MassTextMenu.vue'
import PatientTagManagerDialog from '@/components/dialogs/PatientTagManagerDialog.vue'
import { downloadPtListCSV } from '@/helpers/csv'

export default {
  components: { PatientTagManagerDialog, MassTextMenu },
  props: {
    tableTitle: String,
    selectedPatients: Array,
  },
  data() {
    return {
      downloadPtListCSV,
    }
  },
}
</script>