import { createObjectCsvStringifier } from 'csv-writer'
import { map } from 'lodash'
import time from '@/helpers/time'

/**
 * Generates a CSV of basic patient info: ID (external), firstName, lastName, gender, dob, enrollment date, last active, and QHP time.
 * @param {Array<Patient>} pts 
 * @param {string} title 
 */
export function downloadPtListCSV(pts, title) {
  // the first line is the title
  // REPORT_TITLE: DATE
  // first, last, dob, start date, bp, hr, weight, glucose,
  const dateTimeStr = time.msToUSDateTimeStr(new Date().getTime())
  let csv = title + ' ' + dateTimeStr + '\n'
  
  const csvStringifier = createObjectCsvStringifier({header: [
    {id: 'ptId', title: 'Patient ID'},
    {id: 'ptFirstName', title: 'First Name'},
    {id: 'ptLastName', title: 'Last Name'},
    {id: 'ptGender', title: 'Gender'},
    {id: 'ptDob', title: 'DOB'},
    {id: 'ptEnrolledDate', title: 'Enrolled'},
    {id: 'ptLastActive', title: 'Last Active'},
    {id: 'ptTime', title: 'Time This Period'},
  ]})
  csv += csvStringifier.getHeaderString()
  csv += csvStringifier.stringifyRecords(map(pts, pt => {
    return {
      ptId: pt.org.patientId,
      ptFirstName: pt.firstName,
      ptLastName: pt.lastName,
      ptGender: pt.gender,
      ptDob: pt.displayBirthdate,
      ptEnrolledDate: pt.rpm?.enrollment?.start ? time.msToUSDateTimeStr(pt.rpm.enrollment.start) : '',
      ptLastActive: time.msToUSDateTimeStr(pt.rpm.lastData),
      ptTime: time.msToTimeStr(pt.rpm.qhpTime.ms)
    }
  }))
  
  const hiddenElement = document.createElement('a')
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
  hiddenElement.target = '_blank'
  hiddenElement.download = title + ' ' + dateTimeStr + '_novorpm.csv'
  hiddenElement.click()
}