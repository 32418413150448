const METRICS = {
  BloodGlucose: {
    kind: 'BloodGlucose',
    abbreviation: 'GLU',
    chartType: 'line',
    commonName: 'Glucose',
    device: 'Blood Glucose Meter',
    icon: 'mdi-water',
    iconColor: 'red',
    longName: 'Blood Glucose',
    primaryMetric: true,
    units: 'mg/dL',
  },
  BloodOxygen: {
    kind: 'BloodOxygen',
    abbreviation: 'SpO2',
    chartType: 'line',
    commonName: 'Blood Oxygen',
    device: 'Pulse Oximiter',
    icon: 'mdi-lungs',
    iconColor: 'red',
    longName: 'Blood Oxygen',
    primaryMetric: true,
    units: 'SpO2',
  },
  BloodPressure: {
    kind: 'BloodPressure',
    abbreviation: 'BP',
    chartType: 'line',
    commonName: 'Blood Pressure',
    device: 'Blood Pressure Cuff',
    icon: 'mdi-heart',
    iconColor: 'red',
    longName: 'Blood Pressure',
    primaryMetric: true,
    units: 'mmHg',
  },
  BodyTemp: {
    kind: 'BodyTemp',
    abbreviation: 'TMP',
    chartType: 'line',
    commonName: 'Temp',
    device: 'Thermometer',
    icon: 'mdi-thermometer',
    iconColor: 'grey darken-4',
    longName: 'Temperature',
    primaryMetric: true,
    units: '°F',
  },
  BodyWeight: {
    kind: 'BodyWeight',
    abbreviation: 'WT',
    chartType: 'line',
    commonName: 'Weight',
    device: 'Scale',
    icon: 'mdi-scale-bathroom',
    iconColor: 'grey darken-4',
    longName: 'Weight',
    primaryMetric: true,
    units: 'lbs',
  },
  Ecg: {
    kind: 'Ecg',
    abbreviation: 'ECG',
    chartType: 'line',
    commonName: 'ECG',
    device: 'ECG',
    icon: 'mdi-pulse',
    iconColor: 'grey darken-4',
    longName: 'ECG',
    primaryMetric: true,
    units: '',
  },
  Pulse: {
    kind: 'Pulse',
    abbreviation: 'HR',
    chartType: 'line',
    commonName: 'Heart Rate',
    device: 'Blood Pressure Cuff',
    icon: 'mdi-heart-pulse',
    iconColor: 'red',
    longName: 'Heart Rate',
    primaryMetric: false,
    units: 'bpm',
  },
}
const METRICS_KINDS = new Set(Object.keys(METRICS))
const METRICS_KINDS_OldToNew = {
  MEASUREMENT_BLOOD_GLUCOSE: 'BloodGlucose',
  MEASUREMENT_BLOOD_OXYGEN: 'BloodOxygen',
  MEASUREMENT_BLOOD_PRESSURE: 'BloodPressure',
  MEASUREMENT_BODY_TEMPERATURE: 'BodyTemp',
  MEASUREMENT_BODY_WEIGHT: 'BodyWeight',
  MEASUREMENT_COVID_RISK: 'CovidRisk',
  MEASUREMENT_ECG: 'Ecg',
  MEASUREMENT_PAIN: 'Pain',
  MEASUREMENT_PULSE: 'Pulse',
  RPM_NOTE: 'DeprecatedRpmNote',
}

const DEVICES = {
  BodyTrace_BpCuff_BT105: {
    kind: 'BodyTrace_BpCuff_BT105',
    title: 'Blood Pressure Cuff: BodyTrace BT105',
    subtitle: 'Pre-configured, Cellular enabled',
    image: require('@/assets/bloodpressure_150x150.png'),
    name: 'BodyTrace BP Cuff BT105',
    idLabel: 'Device 15-digit IMEI Number',
    idValidation: validateIMEI,
    availableForOrder: false,
  },
  SmartMeter_iBloodPressure_SMBP802dashGS: {
    kind: 'SmartMeter_iBloodPressure_SMBP802dashGS',
    title: 'Blood Pressure Cuff: Smart Meter iBloodPressure SMBP802-GS',
    subtitle: 'Pre-configured, Cellular enabled',
    image: require('@/assets/smbp_150x150.png'),
    name: 'Smart Meter iBloodPressure',
    idLabel: 'Device 15-digit IMEI Number',
    idValidation: validateIMEI,
    availableForOrder: true,
  },
  Lohman_AfibAlert_V1dot5: {
    kind: 'Lohman_AfibAlert_V1dot5',
    title: 'ECG: Lohman AfibAlert V1.5',
    subtitle: 'Bluetooth enabled',
    image: require('@/assets/ecg_150x150.png'),
    name: 'Lohman AfibAlert',
    idLabel: 'Device ID (6-digit alphanumeric)',
    idValidation: value => /^[a-zA-Z0-9]{6}$/.test(value),
    availableForOrder: false,
  },
  SmartMeter_iGlucose_GM291: {
    kind: 'SmartMeter_iGlucose_GM291',
    title: 'Glucometer: Smart Meter iGlucose GM291',
    subtitle: 'Pre-configured, Cellular enabled',
    image: require('@/assets/glucose_150x150.png'),
    name: 'Smart Meter iGlucose',
    idLabel: 'Device ID Number (7-digit number)',
    idValidation: value => /^[0-9]{7}$/.test(value),
    availableForOrder: true,
  },
  SmartMeter_iPulseOx_SMPO1000dashUS: {
    kind: 'SmartMeter_iPulseOx_SMPO1000dashUS',
    title: 'Pulse Oximeter: Smart Meter iPulseOx SMPO1000',
    subtitle: 'Pre-configured, Cellular enabled',
    image: require('@/assets/smpo_150x150.png'),
    name: 'Smart Meter iPulseOx',
    idLabel: 'Device 15-digit IMEI Number',
    idValidation: validateIMEI,
    availableForOrder: true,
  },
  BodyTrace_Scale_BT005: {
    kind: 'BodyTrace_Scale_BT005',
    title: 'Weight Scale: BodyTrace BT005',
    subtitle: 'Pre-configured, Cellular enabled',
    image: require('@/assets/scale_150x150.png'),
    name: 'BodyTrace Weight Scale BT005',
    idLabel: 'Device 15-digit IMEI Number',
    idValidation: validateIMEI,
    availableForOrder: true,
  },
}

const sortingCategories = {
  timeSinceLastReading: [
    {
      name: 'Less than 24hr',
      cutoff: 86400000,
      tag: '24hr',
      description: 'Patient took a reading within the last 24 hours.',
      color: 0,
    },
    {
      name: 'Less than 48hr',
      cutoff: 86400000 * 2,
      tag: '48hr',
      description: 'Patient took a reading within the last 48 hours.',
      color: 0,
    },
    {
      name: '2-5 Days',
      cutoff: 86400000 * 5,
      tag: '2-5d',
      description: 'Patient took a reading within the last 5 days.',
      color: 1,
    },
    {
      name: '6-14 Days',
      cutoff: 86400000 * 14,
      tag: '6-14d',
      description: 'Patient took a reading within the last 30 days.',
      color: 1,
    },
    {
      name: '15+ Days',
      cutoff: 86400000 * 14,
      tag: '15d+',
      description: 'Patient hasnt taken a reading in 30 days or more.',
      color: 2,
    },
    { name: 'No Readings', cutoff: 0, tag: 'never', description: 'Patient has never taken a reading', color: 3 },
  ],
  qhpTimeThisMonth: [
    {
      name: '60 min or more',
      cutoff: 3600000,
      tag: 'qhp60+',
      description: 'More than 60 minutes of health coaching this month.',
      color: 0,
    },
    {
      name: '40 min or more',
      cutoff: 60000 * 40,
      tag: 'qhp40+',
      description: 'More than 40 minutes of health coaching this month.',
      color: 0,
    },
    {
      name: '20 min or more',
      cutoff: 60000 * 20,
      tag: 'qhp20+',
      description: 'More than 20 minutes of health coaching this month.',
      color: 0,
    },
    {
      name: '10 min or more',
      cutoff: 60000 * 10,
      tag: 'qhp10+',
      description: 'More than 10 minutes of health coaching this month.',
      color: 0,
    },
    { name: '1 min or more', cutoff: 1, tag: 'qhp1+', description: 'Some health coaching this month', color: 1 },

    { name: 'Zero minutes', cutoff: 0, tag: 'qhp0', description: 'No health coaching yet this month', color: 2 },
  ],
  engagementPerfectionNeeded: [
    {
      name: 'Complete',
      cutoff: 0,
      tag: 'complete',
      description: 'Already met 16 day requirement in current period',
      color: 0,
    },
    {
      name: 'On Track',
      cutoff: 50,
      tag: 'ontrack',
      description: 'High chance of getting 16 days of readings this period',
      color: 0,
    },
    {
      name: 'Moderate Risk',
      cutoff: 70,
      tag: 'moderate',
      description: 'Moderate risk of not getting 16 days this period',
      color: 1,
    },
    {
      name: 'High Risk',
      cutoff: 100,
      tag: 'high',
      description: 'High risk of not getting 16 days this period.',
      color: 2,
    },
    {
      name: 'Ineligible',
      cutoff: 100,
      tag: 'ineligible',
      description: 'Unable to get 16 days of readings in current period.',
      color: 3,
    },
    { name: 'No Readings', cutoff: null, tag: 'inactive', description: 'Never taken a reading', color: 4 },
  ],
  enrollmentAge: [
    {
      name: 'Recently Enrolled',
      cutoff: 86400000 * 30,
      tag: 'beginner',
      description: 'Patients enrolled within the last 30 days',
      color: 0,
    },
    {
      name: '30-60 days',
      cutoff: 86400000 * 60,
      tag: 'experienced',
      description: 'Patients enrolled within the last 30-60 days',
      color: 0,
    },
    {
      name: '60-90 days',
      cutoff: 86400000 * 90,
      tag: 'expert',
      description: 'Patients enrolled within the last 60-90 days',
      color: 0,
    },
    {
      name: '90-180 days',
      cutoff: 86400000 * 180,
      tag: 'expert',
      description: 'Patients enrolled within the last 90-180 days',
      color: 0,
    },
    {
      name: '180d+',
      cutoff: 86400000 * 180,
      tag: 'vetran',
      description: 'Patients enrolled for 90 days or more',
      color: 0,
    },
    {
      name: 'Not Enrolled',
      cutoff: 0,
      tag: 'NE',
      description: 'Not Enrolled',
      color: 0,
    },
  ],
}
const colorsForGraphs = {
  success: ['#4CAF50', '#66BB6A', '#81C784', '#A5D6A7'],
  warning: ['#FDD835', '#FBC02D', '#F9A825', '#F57F17'],
  failure: ['#F44336', '#D32F2F', '#C62828'],
  issue: ['#424242', '#212121'],
}

function validateIMEI(value) {
  // Must be 15 digits
  if (!/^[0-9]{15}$/.test(value)) return false

  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0,
    bEven = false
  value = value.replace(/\D/g, '')

  for (let n = value.length - 1; n >= 0; n--) {
    let cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10)

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9

    nCheck += nDigit
    bEven = !bEven
  }

  return nCheck % 10 === 0
}

export default {
  privacyUrl: 'https://storage.googleapis.com/onechart-prod/themes/patient/privacy.html',
  termsUrl: 'https://storage.googleapis.com/onechart-prod/themes/patient/termsofuse.html',
  DEVICES,
  METRICS,
  METRICS_KINDS,
  METRICS_KINDS_OldToNew,
  sortingCategories,
  colorsForGraphs,
}
