<template>
  <v-card class="flex" color="transparent" flat tile>
    <v-row style="padding-left: 10px" class="pr-0 fill-height" no-gutters align="center">
      <slot />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            depressed
            v-bind="attrs"
            v-on="on"
            @click="downloadPtListCSV(selectedPatients, 'Selected Patients')"
            icon
            :disabled="selectedPatients.length === 0"
          >
            <v-icon>mdi-cloud-download-outline</v-icon>
          </v-btn>
        </template>
        Export Patient List
      </v-tooltip>

      <MassTextMenu :alwaysOn="true" :selectedPatients="selectedPatients" ref="massText" />
      <patient-tag-manager-dialog :alwaysOn="true" :selectedPatients="selectedPatients" ref="tagManagerDialog" />
      <v-spacer />

      <slot name="sortSlot" />
    </v-row>
  </v-card>
</template>

<script>
import MassTextMenu from '@/components/dialogs/MassTextMenu.vue'
import PatientTagManagerDialog from '@/components/dialogs/PatientTagManagerDialog.vue'
import { downloadPtListCSV } from '@/helpers/csv'

export default {
  components: { PatientTagManagerDialog, MassTextMenu },
  props: {
    tableTitle: String,
    selectedPatients: Array,
  },
  data() {
    return {
      downloadPtListCSV,
    }
  },
}
</script>
