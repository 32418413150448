import Vue from 'vue'
import VueRouter from 'vue-router'
import authGuard from './authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Default',
    component: () => import(/* webpackChunkName: "default" */ '../views/Login.vue'),
    meta: {
      hideHeader: true,
      centerVertical: true,
    },
  },
  {
    path: '/enterprise',
    name: 'Enterprise',
    component: () => import(/* webpackChunkName: "default" */ '../views/EnterpriseHUD.vue'),
    meta: {
      hideHeader: false,
      customHeader: true,
      displayName: 'Enterprise',
      mobileDisplayName: 'Enterprise',
      requireUser: true,
      requireOrg: true,
      requireEnrolledPatients: true,
      borderless: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      hideHeader: true,
      centerVertical: true,
    },
  },
  {
    path: '/policies',
    name: 'Policies',
    component: () => import(/* webpackChunkName: "policies" */ '../views/Policies.vue'),
    meta: {
      hideHeader: true,
      centerVertical: true,
    },
  },
  {
    path: '*',
    name: 'DefaultPage',
    component: () => import(/* webpackChunkName: "default-page" */ '../views/Login.vue'),
    meta: {
      hideHeader: true,
      centerVertical: true,
    },
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: () => import(/* webpackChunkName: "password-reset" */ '../views/PasswordReset.vue'),
    meta: {
      hideHeader: true,
      centerVertical: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: {
      hideHeader: true,
      centerVertical: true,
    },
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    meta: {
      hideHeader: true,
    },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
    meta: {
      hideHeader: true,
    },
  },
  {
    path: '/inbox',
    name: 'Inbox',
    props: true,
    component: () => import(/* webpackChunkName: "inbox" */ '../views/PopulationView.vue'),
    meta: {
      hideHeader: false,
      customHeader: true,
      displayName: 'Taskboard',
      mobileDisplayName: 'Taskboard',
      requireUser: true,
      requireOrg: true,
      requireEnrolledPatients: true,
      borderless: true,
      appBarExtension: false,
      expandedDrawer: true,
    },
    beforeEnter(to, from, next) {
      if (
        (from.name === 'Login' || from.name === 'Default' || from.name === 'DefaultPage') &&
        from.query.redirect_after_login
      ) {
        location.href = from.query.redirect_after_login
      }
      return authGuard.beforeEnter(to, from, next)
    },
  },
  {
    path: '/devicemanagement',
    name: 'Device Management',
    component: () => import(/* webpackChunkName: "taskboard" */ '../views/DeviceManagement.vue'),
    meta: {
      hideHeader: false,
      customHeader: true,
      displayName: 'Device Management',
      mobileDisplayName: 'Device Management',
      requireUser: true,
      requireOrg: true,
      requireEnrolledPatients: true,
      borderless: true,
      appBarExtension: false,
      expandedDrawer: false,
      hideOnlyHeader: false,
    },
    beforeEnter: authGuard.beforeEnter,
  },
  {
    path: '/conversations',
    name: 'Conversations',
    component: () => import(/* webpackChunkName: "convos" */ '../views/Conversations.vue'),
    meta: {
      hideHeader: false,
      customHeader: true,
      displayName: 'Conversations',
      mobileDisplayName: 'Conversations',
      requireUser: true,
      requireOrg: true,
      requireEnrolledPatients: true,
      borderless: true,
    },
    beforeEnter: authGuard.beforeEnter,
  },
  {
    path: '/inbox/:id',
    name: 'Patient',
    component: () => import(/* webpackChunkName: "patient" */ '../views/Patient.vue'),
    meta: {
      hideHeader: false,
      showPatientBar: true,
      displayName: 'Chart',
      mobileDisplayName: 'Chart',
      hideFooter: true,
      requireUser: true,
      requireOrg: true,
      requirePatient: true,
    },
    children: [
      {
        path: 'patientinfo',
        name: 'PatientInfo',
        component: () => import(/* webpackChunkName: "patient-info" */ '../views/PatientInfo.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Information',
          mobileDisplayName: 'Info',
          hideFooter: true,
          requireUser: true,
          requireOrg: true,
          expandedDrawer: true,
          requirePatient: true,
        },
      },
      {
        path: 'feed',
        name: 'PatientFeed',
        component: () => import(/* webpackChunkName: "patient-feed" */ '../views/PatientFeed.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Feed',
          mobileDisplayName: 'Feed',
          hideFooter: true,
          requireUser: true,
          requireOrg: true,
          showPatientNotificationsOnLoad: true,
          expandedDrawer: true,
          requirePatient: true,
        },
      },
      {
        path: 'devices',
        name: 'Devices',
        component: () => import(/* webpackChunkName: "patient-devices" */ '../views/PatientDevices.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Devices',
          mobileDisplayName: 'Devices',
          hideFooter: true,
          requireUser: true,
          expandedDrawer: true,
          requirePatient: true,
        },
      },
      {
        path: 'messages',
        name: 'Messages',
        component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Messages',
          mobileDisplayName: 'Messages',
          requireUser: true,
          requireOrg: true,
          expandedDrawer: true,
          requirePatient: true,
        },
      },
    ],
    beforeEnter: authGuard.beforeEnter,
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: {
      hideHeader: false,
      showPatientBar: false,
      displayName: 'Account',
      mobileDisplayName: 'Account',
      hideFooter: true,
      requireUser: true,
      requireOrg: true,
    },
    beforeEnter: authGuard.beforeEnter,
  },
  {
    path: '/org/:id',
    name: 'Organization',
    component: () => import(/* webpackChunkName: "organization" */ '../views/Organization.vue'),
    meta: {
      hideHeader: false,
      showPatientBar: false,
      displayName: 'Organization',
      mobileDisplayName: 'Organization',
      requireUser: true,
      requireOrg: true,
    },
    beforeEnter: authGuard.beforeEnter,
  },
  {
    path: '/enrollment/:id',
    name: 'Enrollment',
    component: () => import(/* webpackChunkName: "enrollment" */ '../views/Enrollment.vue'),
    meta: {
      hideHeader: false,
      showPatientBar: false,
      displayName: 'Enrollment',
      mobileDisplayName: 'Enrollment',
      requireUser: true,
      requireOrg: true,
    },
    beforeEnter: authGuard.beforeEnter,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
