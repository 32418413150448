<template>
  <v-row v-if="listPatient" no-gutters>
    <v-card
      v-if="!listPatient.noteForDisplay"
      @click="$emit('clicked', listPatient)"
      :color="patientRoute === listPatient.id ? 'white' : 'transparent'"
      flat
      class="flex pt-2"
      rounded="lg"
      tile
    >
      <v-row class="pr-4 pl-1" no-gutters>
        <div>
          <div>
            <v-row no-gutters justify="center">
              <v-simple-checkbox
                class="ml-1 mt-1"
                :value="selected"
                :color="selected ? 'primary' : 'green'"
                @click.stop.prevent="$emit('toggleSelected')"
                :ripple="false"
              ></v-simple-checkbox>
            </v-row>
          </div>
          <div>
            <v-btn class="mt-1 mr-1" @click.stop.prevent="toggleStarred()" icon>
              <v-icon color="#F7CB4E" v-if="listPatient.tags.includes('Care Team Starred')">mdi-star</v-icon>
              <v-icon color="grey lighten-2" v-else>mdi-star-outline</v-icon>
            </v-btn>
          </div>
        </div>
        <v-col class="flex pt-1">
          <v-row align="center" style="font-weight: 600" no-gutters>
            <span>{{ listPatient.firstName }} {{ listPatient.lastName }}</span>

            <v-spacer />
            <div style="font-size: 11px; position: relative; width: 50px; color: white">
              <v-progress-linear
                style="width: 50px"
                height="16"
                rounded
                :value="(listPatient.rpm.qhpTime.millisecondsThisPeriod / 1200000) * 100"
              />
              <div style="position: absolute; top: 0px; left: 0px; text-align: center; width: 50px">
                {{ msToMinutesAndSeconds(listPatient.rpm.qhpTime.millisecondsThisPeriod) }}
              </div>
            </div>
          </v-row>
          <v-row
            v-if="listPatient.daysThisPeriodWithReadings || listPatient.daysLeftToUse"
            align="center"
            style="font-size: 13px"
            no-gutters
          >
            <span v-if="listPatient.daysThisPeriodWithReadings"
              >{{ listPatient.daysThisPeriodWithReadings }} Reading<span
                v-if="listPatient.daysThisPeriodWithReadings !== 1"
                >s</span
              ></span
            >
            <span v-if="listPatient.daysLeftToUse" class="ml-1"
              >{{ listPatient.daysLeftToUse }} Day<span v-if="listPatient.daysLeftToUse !== 1">s</span> left</span
            >
            <v-spacer />
          </v-row>
          <v-row
            class="pt-4"
            v-if="listPatient.rpm && listPatient.rpm.lastReadings && listPatient.rpm.lastData"
            style="font-weight: 400"
            no-gutters
          >
            <v-card
              flat
              class="mb-1 mr-1 px-2 py-1"
              :color="event.unreadAlert ? 'yellow' : 'transparent'"
              small
              label
              v-for="(event, index) in eventsArray"
              :key="index"
            >
              <v-row align="start" no-gutters>
                <v-icon small class="mt-1" left>{{ event.icon }}</v-icon>
                <v-col>
                  <v-row no-gutters>
                    <span style="font-size: 13px"
                      >{{ event.displayValue || '-' }}
                      <span style="font-size: 11px">{{ event.units || '' }}</span></span
                    >
                  </v-row>
                  <v-row no-gutters>
                    <span style="font-size: 11px">{{ event.timeAgo }}</span>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row no-gutters v-if="!listPatient.rpm || !listPatient.rpm.lastData" style="opacity: 0.6; font-size: 13px">
            No readings yet</v-row
          >
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-0" />
    </v-card>
    <v-card
      v-if="listPatient.noteForDisplay"
      @click="$emit('clicked', listPatient.patient)"
      :color="patientRoute === listPatient.patient.id ? 'white' : 'transparent'"
      flat
      class="flex pt-2"
      rounded="lg"
      tile
    >
      <v-row class="pr-0" no-gutters>
        <div style="width: 48px">
          <v-row no-gutters justify="center">
            <v-simple-checkbox
              disabled
              class="ml-1 mt-1"
              :value="selected"
              :color="selected ? 'primary' : 'green'"
              :ripple="false"
            ></v-simple-checkbox>
          </v-row>
        </div>
        <v-col class="flex pl-2 pr-4" style="padding-top: 2px">
          <v-row align="center" style="font-weight: 600; font-size: 14px; margin-bottom: 5px" no-gutters>
            <v-col>
              <v-row class="text-truncate" style="width: 135px" no-gutters>
                <span class="text-truncate"
                  >{{ listPatient.patient.firstName }} {{ listPatient.patient.lastName }}</span
                >
              </v-row>
            </v-col>
            <div>
              <span class="text-body-2 font-weight-regular">{{
                specificRelativeTime(listPatient.noteForDisplay.date)
              }}</span>
            </div>
          </v-row>
          <div class="pb-2">
            <v-row class="text--secondary" justify="space-between" no-gutters>
              <span class="text-body-2"
                ><span>{{ listPatient.noteForDisplay.author }}: </span>
                <span>
                  <span v-if="listPatient.noteForDisplay.text.length < 149" class="text-body-2">{{
                    listPatient.noteForDisplay.text
                  }}</span>
                  <span class="text-body-2" v-else>{{ listPatient.noteForDisplay.text.slice(0, 150) }}...</span>
                </span>
              </span>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import { msToMinutesAndSeconds, specificRelativeTime } from '@/helpers/time'

export default defineComponent({
  props: {
    listPatient: Object,
    patientRoute: String,
    selected: Boolean,
  },
  setup(props, context) {
    const rootStore = context.root.$store // TODO: move our modules from vuex to composition api
    const eventsArray = computed(() => {
      const events = []
      if (props.listPatient.rpm) {
        for (const [k, v] of Object.entries(props.listPatient.rpm.lastReadings)) {
          if (dayjs().diff(v.timestamp, 'days') >= 182) continue
          v.unreadAlert = props.listPatient.rpm.newAlertsArray.includes(k)
          events.push(v)
        }
        return events.sort((a, b) => {
          if (a.unreadAlert > b.unreadAlert) return -1
          if (a.unreadAlert < b.unreadAlert) return 1
          if (a.tsMs > b.tsMs) return -1
          if (a.tsMs < b.tsMs) return 1
          return 0
        })
      }
      return events
    })

    const toggleStarred = async () => {
      const tag = 'Care Team Starred'
      const ptTags = props.listPatient.tags
      const careTeamStarredTagIdx = ptTags.indexOf(tag)
      if (careTeamStarredTagIdx === -1) ptTags.push(tag)
      else ptTags.splice(careTeamStarredTagIdx, 1)
      await rootStore.dispatch('patient/oneOffUpdate', {
        patientId: props.listPatient.id,
        payload: { tags: { value: ptTags } },
      })
    }

    return { eventsArray, toggleStarred, msToMinutesAndSeconds, specificRelativeTime }
  },
})
</script>
