var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","fixed":"","mini-variant-width":"60","width":450,"mini-variant":!this.$route.meta.expandedDrawer,"color":"white","mobile-breakpoint":"0","permanent":"","touchless":"","stateless":"","floating":""},on:{"transitionend":function($event){_vm.transitioning = false}}},[_c('v-row',{staticClass:"fill-height flex-nowrap",staticStyle:{"overscroll-behavior":"contain"},attrs:{"no-gutters":""}},[_c('v-navigation-drawer',{attrs:{"fixed":"","floating":"","dark":"","color":"#001426","mini-variant":"","mini-variant-width":"60","permanent":"","stateless":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.org)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({on:{"click":function($event){return _vm.$emit('turnOffEnhanced')}},model:{value:(_vm.isDisplayingReports),callback:function ($$v) {_vm.isDisplayingReports=$$v},expression:"isDisplayingReports"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chart-donut")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Enhanced View")])],1)],1)]}}],null,false,3494160608)},[_vm._v(" Toggle Enhanced View ")]):_vm._e()]},proxy:true}])},[(_vm.orgSwitcherOptions.length > 1)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"py-1",attrs:{"to":{ name: 'Enterprise' }},model:{value:(_vm.isDisplayingEnterprise),callback:function ($$v) {_vm.isDisplayingEnterprise=$$v},expression:"isDisplayingEnterprise"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-img',{attrs:{"src":require('@/assets/Arrowhealth-bug-RGB.png')}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Enterprise")])],1)],1)]}}],null,false,2904647980)},[_c('span',[_vm._v("Enterprise Statistics")])]):_vm._e(),(_vm.orgSwitcherOptions.length === 1)?_c('v-list-item',{on:{"click":function($event){return _vm.navigateHome()}}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"src":require('@/assets/Arrowhealth-bug-RGB.png')}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dashboard")])],1)],1):_vm._e(),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":{ name: 'Inbox' }},model:{value:(_vm.isDisplayingDashboard),callback:function ($$v) {_vm.isDisplayingDashboard=$$v},expression:"isDisplayingDashboard"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Inbox")])],1)],1)]}}])},[_c('span',[_vm._v("Inbox")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":{ name: 'Conversations' }},model:{value:(_vm.isDisplayingMessaging),callback:function ($$v) {_vm.isDisplayingMessaging=$$v},expression:"isDisplayingMessaging"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-badge',{attrs:{"value":_vm.unreadConvosCount,"content":_vm.unreadConvosCount,"color":"primary","overlap":""}},[_c('v-icon',[_vm._v("mdi-message-reply-text")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Conversations")])],1)],1)]}}])},[_c('span',[_vm._v("Conversations")])]),(false)?_c('app-drawer-user-navigation-list'):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":_vm.accountRoute},model:{value:(_vm.isDisplayingAccount),callback:function ($$v) {_vm.isDisplayingAccount=$$v},expression:"isDisplayingAccount"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))])],1)],1)]}}])},[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")]),_c('v-fade-transition',[(_vm.org)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"exact":"","to":_vm.orgRoute},model:{value:(_vm.isDisplayingOrg),callback:function ($$v) {_vm.isDisplayingOrg=$$v},expression:"isDisplayingOrg"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-domain")])],1),_c('v-list-item-content',[_c('v-list-item-title')],1)],1)]}}],null,false,685858402)},[_vm._v(" "+_vm._s(_vm.org.name)+" ")]):_vm._e()],1),_c('v-fade-transition',[(_vm.org)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":_vm.enrollmentRoute},model:{value:(_vm.isDisplayingEnrollment),callback:function ($$v) {_vm.isDisplayingEnrollment=$$v},expression:"isDisplayingEnrollment"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{staticClass:"rotate"},[_vm._v("mdi-account-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Enrollment")])],1)],1)]}}],null,false,301659696)},[_vm._v(" Enrollment ")]):_vm._e()],1),_c('v-fade-transition',[(_vm.org)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":_vm.deviceRoute},model:{value:(_vm.isDisplayingDeviceManagement),callback:function ($$v) {_vm.isDisplayingDeviceManagement=$$v},expression:"isDisplayingDeviceManagement"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{staticClass:"rotate"},[_vm._v("mdi-devices")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Devices")])],1)],1)]}}],null,false,3724480561)},[_vm._v(" Devices ")]):_vm._e()],1),_c('v-fade-transition',[(_vm.org)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({on:{"click":function($event){return _vm.openNewBillingPlatform()}},model:{value:(_vm.isDisplayingReports),callback:function ($$v) {_vm.isDisplayingReports=$$v},expression:"isDisplayingReports"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-google-spreadsheet")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Reports")])],1)],1)]}}],null,false,3366173072)},[_vm._v(" Go to reports ")]):_vm._e()],1)],1)],1),_c('v-col',{staticStyle:{"overflow-y":"scroll","overflow-x":"hidden","background-color":"#eff2f5","padding-left":"60px"},attrs:{"id":"inboxScrollbox"}},[(!_vm.transitioning)?_c('div',[_c('v-card',{staticClass:"flex",staticStyle:{"position":"sticky","top":"0","z-index":"100","backdrop-filter":"blur(2px)","border-right":"1px solid transparent"},attrs:{"height":"64","flat":"","tile":"","color":"#eff2f5cc"}},[_c('v-row',{staticClass:"fill-height px-3 flex",attrs:{"align":"center","no-gutters":""}},[_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.orgSwitcherOptions.length > 0 && !_vm.searchFocused)?_c('v-select',{staticStyle:{"font-weight":"bold"},attrs:{"hide-details":"","solo":"","flat":"","background-color":"transparent","placeholder":"ORG SWITCHER","items":_vm.orgSwitcherOptions,"return-object":""},on:{"change":_vm.switchOrg},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center"}},[_c('v-avatar',{staticClass:"white--text mr-2",attrs:{"size":"32","color":"primary"}},[_c('span',{staticStyle:{"font-weight":"bold","font-size":"14px"}},[_vm._v(" "+_vm._s(item.name.split(' ')[0].charAt(0))),(item.name.split(' ')[1])?_c('span',[_vm._v(_vm._s(item.name.split(' ')[1].charAt(0)))]):_vm._e()])]),_c('span',{staticClass:"text-truncate",staticStyle:{"width":"270px"}},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}}],null,false,1661037208),model:{value:(_vm.orgSwitcherSelection),callback:function ($$v) {_vm.orgSwitcherSelection=$$v},expression:"orgSwitcherSelection"}}):_vm._e()],1)],1)],1),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[_c('div',{staticStyle:{"height":"56px"}},[_c('v-row',{staticClass:"mx-3 fill-height",attrs:{"align":"start","no-gutters":""}},[_c('global-patient-search-simplified',{attrs:{"orgsDict":_vm.fullOrgsDict},on:{"blurred":function($event){_vm.searchFocused = false},"searchResultPatient":_vm.searchResultPatient}})],1)],1)]),_c('v-slide-y-reverse-transition',[(!_vm.patientsLoading)?_c('inbox-patient-list',{attrs:{"headerColor":"#EFF2F5"},on:{"clicked":_vm.handleClick}}):_vm._e()],1)],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }