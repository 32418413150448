<template>
  <v-row v-if="listPatient" no-gutters>
    <div style="width: 100%; border-bottom: 1px solid #d2d5d7">
      <v-card
        v-if="!listPatient.noteForDisplay"
        @click="$emit('clicked', listPatient)"
        :color="patientRoute === listPatient.id ? 'white' : 'transparent'"
        flat
        class="flex py-2"
        rounded="lg"
        tile
        style="overflow: hidden"
      >
        <v-row class="pr-0" no-gutters>
          <div style="width: 48px">
            <v-row no-gutters justify="center">
              <v-simple-checkbox
                class="ml-1 mt-1"
                :value="selected"
                :color="selected ? 'primary' : 'green'"
                @click.stop.prevent="$emit('toggleSelected')"
                :ripple="false"
              ></v-simple-checkbox>
            </v-row>

            <v-row no-gutters justify="center">
              <v-btn class="mt-1" style="margin-left: -4px" @click.stop.prevent="toggleStarred()" icon>
                <v-icon color="#F7CB4E" v-if="listPatient.tags.includes('Care Team Starred')">mdi-star</v-icon>
                <v-icon color="grey lighten-2" v-else>mdi-star-outline</v-icon>
              </v-btn>
            </v-row>
          </div>
          <v-col class="flex pl-2" style="padding-top: 2px">
            <v-row class="pr-4" align="center" style="font-weight: 600; font-size: 14px" no-gutters>
              <v-col>
                <v-row class="text-truncate" style="width: 200px" no-gutters>
                  <span class="text-truncate">{{ listPatient.firstName }} {{ listPatient.lastName }}</span>
                </v-row>
              </v-col>
              <div>
                <v-row no-gutters class="flex" justify="end" align="center">
                  <v-icon
                    v-if="listPatient.rpm.qhpTime.millisecondsThisPeriod > 60000 * 20"
                    style="margin-left: -20px; margin-right: 5px"
                    color="green"
                    small
                    >mdi-check-circle</v-icon
                  >
                  {{ msToMinutesAndSeconds(listPatient.rpm.qhpTime.millisecondsThisPeriod) }}
                </v-row>
              </div>
            </v-row>

            <v-row
              v-if="
                (listPatient.daysThisPeriodWithReadings || listPatient.daysLeftToUse) &&
                !listPatient.systemTags.includes('15d+')
              "
              align="center"
              no-gutters
              style="margin-bottom: 15px; font-size: 14px"
              class="pr-4"
            >
              <span v-if="listPatient.daysThisPeriodWithReadings"
                >{{ listPatient.daysThisPeriodWithReadings }} Reading<span
                  v-if="listPatient.daysThisPeriodWithReadings !== 1"
                  >s</span
                ></span
              >
              <span v-if="listPatient.daysLeftToUse" class="ml-1"
                >{{ listPatient.daysLeftToUse }} Day<span v-if="listPatient.daysLeftToUse !== 1">s</span> left</span
              >
              <v-spacer />
              <span :style="'font-weight:500;  opacity: 1; color:' + engagementLevelTextAndColor.color">{{
                engagementLevelTextAndColor.text
              }}</span>
            </v-row>

            <v-row
              v-if="listPatient.systemTags.includes('15d+')"
              align="center"
              no-gutters
              style="margin-bottom: 15px; font-size: 14px"
              class="pr-4"
            >
              <span style="color: red">No readings in 15+ days</span>
              <v-spacer />
              <span :style="'font-weight:500;  opacity: 1; color:' + engagementLevelTextAndColor.color">{{
                engagementLevelTextAndColor.text
              }}</span>
            </v-row>

            <!-- <v-row align="center" no-gutters style="font-size: 14px" class="pr-4">
              <span>Enrolled: {{ specificRelativeTime(listPatient.enrollmentTimestamp) }}</span>
            </v-row> -->

            <v-row
              class="mr-4"
              v-if="listPatient.rpm && listPatient.rpm.lastReadings && listPatient.rpm.lastData"
              style="font-weight: 400"
              no-gutters
            >
              <v-card
                flat
                class="mb-4 mr-1 px-2 py-1"
                :color="event.unreadAlert ? 'yellow' : 'transparent'"
                small
                label
                v-for="(event, index) in eventsArray"
                :key="index"
              >
                <v-row align="start" no-gutters>
                  <v-icon small class="mt-1" left>{{ event.icon }}</v-icon>
                  <v-col>
                    <v-row no-gutters>
                      <span style="font-size: 13px"
                        >{{ event.displayValue || '-' }}
                        <span style="font-size: 11px">{{ event.units || '' }}</span></span
                      >
                    </v-row>
                    <v-row no-gutters>
                      <span class="text-truncate" style="font-size: 11px">{{ event.timeAgo }}</span>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>

            <v-card
              v-if="!listPatient.rpm || !listPatient.rpm.lastData"
              flat
              class="mr-4 pt-6 mt-3"
              height="50"
              color="grey lighten-2"
            >
              <v-row class="fill-height" align="center" justify="center"> <span> No readings yet</span></v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-card
        v-if="listPatient.noteForDisplay"
        @click="$emit('clicked', listPatient.patient)"
        :color="patientRoute === listPatient.patient.id ? 'white' : 'transparent'"
        flat
        class="flex pt-2"
        rounded="lg"
        tile
      >
        <v-row class="pr-0" no-gutters>
          <div style="width: 48px">
            <v-row no-gutters justify="center">
              <v-simple-checkbox
                class="ml-1 mt-1"
                :value="selected"
                :color="selected ? 'primary' : 'green'"
                @click.stop.prevent="$emit('toggleSelected')"
                :ripple="false"
              ></v-simple-checkbox>
            </v-row>
          </div>
          <v-col class="flex pl-2 pr-4" style="padding-top: 2px">
            <v-row align="center" style="font-weight: 600; font-size: 14px; margin-bottom: 5px" no-gutters>
              <v-col>
                <v-row class="text-truncate" style="width: 135px" no-gutters>
                  <span class="text-truncate"
                    >{{ listPatient.patient.firstName }} {{ listPatient.patient.lastName }}</span
                  >
                </v-row>
              </v-col>
              <div>
                <span class="text-body-2 font-weight-regular">{{
                  specificRelativeTime(listPatient.noteForDisplay.date)
                }}</span>
              </div>
            </v-row>
            <div class="pb-2">
              <v-row class="text--secondary" justify="space-between" no-gutters>
                <span class="text-body-2"
                  ><span>{{ listPatient.noteForDisplay.author }}: </span>
                  <span>
                    <span v-if="listPatient.noteForDisplay.text.length < 149" class="text-body-2">{{
                      listPatient.noteForDisplay.text
                    }}</span>
                    <span class="text-body-2" v-else>{{ listPatient.noteForDisplay.text.slice(0, 150) }}...</span>
                  </span>
                </span>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-row>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import { msToMinutesAndSeconds, specificRelativeTime } from '@/helpers/time'
import CONSTS from '@/consts'

export default defineComponent({
  props: {
    listPatient: Object,
    patientRoute: String,
    selected: Boolean,
    headers: Array,
  },
  setup(props, context) {
    const rootStore = context.root.$store // TODO: move our modules from vuex to composition api
    const eventsArray = computed(() => {
      const events = []
      for (const [k, v] of Object.entries(props.listPatient.rpm.lastReadings)) {
        if (dayjs().diff(v.timestamp, 'days') >= 182) continue
        v.unreadAlert = props.listPatient.rpm.newAlertsArray.includes(k)
        events.push(v)
      }
      return events.sort((a, b) => {
        if (a.unreadAlert > b.unreadAlert) return -1
        if (a.unreadAlert < b.unreadAlert) return 1
        if (a.tsMs > b.tsMs) return -1
        if (a.tsMs < b.tsMs) return 1
        return 0
      })
    })

    const engagementLevelTextAndColor = computed(() => {
      let category = props.listPatient.sort.onTrackCategory
      let details = CONSTS.sortingCategories.engagementPerfectionNeeded.find(e => e.tag === category)
      if (details) {
        let color = '#4485ed'
        if (details.color === 0) {
          color = CONSTS.colorsForGraphs.success[0]
        }
        if (details.color === 1) {
          color = CONSTS.colorsForGraphs.warning[2]
        }
        if (details.color === 2) {
          color = CONSTS.colorsForGraphs.failure[0]
        }
        if (details.color > 2) {
          color = CONSTS.colorsForGraphs.issue[0]
        }
        return { text: details.name, color: color }
      }
      return { text: null, color: null }
    })

    const toggleStarred = async () => {
      const tag = 'Care Team Starred'
      const ptTags = props.listPatient.tags
      const careTeamStarredTagIdx = ptTags.indexOf(tag)
      if (careTeamStarredTagIdx === -1) ptTags.push(tag)
      else ptTags.splice(careTeamStarredTagIdx, 1)
      await rootStore.dispatch('patient/oneOffUpdate', {
        patientId: props.listPatient.id,
        payload: { tags: { value: ptTags } },
      })
    }

    return { eventsArray, toggleStarred, msToMinutesAndSeconds, engagementLevelTextAndColor, specificRelativeTime }
  },
})
</script>
