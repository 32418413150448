import { render, staticRenderFns } from "./InboxPatientList.vue?vue&type=template&id=6d8b6dde&scoped=true&"
import script from "./InboxPatientList.vue?vue&type=script&lang=js&"
export * from "./InboxPatientList.vue?vue&type=script&lang=js&"
import style0 from "./InboxPatientList.vue?vue&type=style&index=0&id=6d8b6dde&scoped=true&lang=css&"
import style1 from "./InboxPatientList.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d8b6dde",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VChip,VCol,VContainer,VDivider,VIcon,VLazy,VRow,VSelect,VSimpleCheckbox,VSlideXReverseTransition,VSlideYReverseTransition,VSpacer,VTabItem,VTabsItems,VTooltip})
