import { prepPat } from '@/helpers/patient'
import { prepUser } from '../../../helpers/user'

export default {
  reset(state) {
    for (const hook of state._resetHooks) hook()
    state._resetHooks = []
    state._orgId = null

    state.org = null
    state.orgLoading = false

    state.invites = []
    state.invitesLoading = false

    state.patients = []
    state.patientsLoading = false
    state.patientsDict = {}
    state.enrolledPatients = []
    state.unenrolledPatients = []
    state.enrolledPatientsZips = []

    state.settings = {}
    state.settingsLoading = false

    state.unreadConvosCount = 0

    state.users = []
    state.usersLoading = false
    state.usersDict = {}

    state.providerReviewNotifications = []
    state.careTeamReviewNotifications = []
    state.mentions = []
    state.ticklers = []
  },
  _addResetHook(state, hook) {
    state._resetHooks.push(hook)
  },
  _setOrgId(state, orgId) {
    state._orgId = orgId
  },
  _setOrg(state, org) {
    state.org = org
  },
  _setOrgLoading(state, bool) {
    state.orgLoading = bool
  },
  _setInvites(state, invites) {
    state.invites = invites
  },
  _setInvitesLoading(state, bool) {
    state.invitesLoading = bool
  },
  _setUnpreppedPatients(state, unpreppedPatients) {
    for (const p of unpreppedPatients) prepPat(p)
    this.commit('org/_setPatients', unpreppedPatients)
  },
  _setPatients(state, patients) {
    // Sort by, first: most recent data, second: most qhp time. Follows previous implementation
    patients.sort((x, y) => {
      if (x.rpm.lastData < y.rpm.lastData) return 1 // x older data than y. Sort after.
      if (x.rpm.lastData > y.rpm.lastData) return -1 // x newer data than y. Sort before.
      if (x.rpm.qhpTime.millisecondsThisPeriod > y.rpm.qhpTime.millisecondsThisPeriod) return -1 // x has more time, sort before.
      return 1
    })

    const patientsDict = {}
    const enrolledPatients = []
    const unenrolledPatients = []
    const enrolledPatientsZips = []
    for (const p of patients) {
      patientsDict[p.id] = p
      if (p.enrolled) {
        enrolledPatients.push(p)
        const existingZip = enrolledPatientsZips.find(zipObj => zipObj.text === p.zip)
        if (existingZip) {
          existingZip.count++
        } else {
          enrolledPatientsZips.push({ text: p.zip, count: 1, ex: p.fullAddress })
        }
      } else {
        unenrolledPatients.push(p)
      }
    }
    enrolledPatientsZips.sort((x, y) => (x.text > y.text ? 1 : -1)) // sort zips ascending
    state.patientsLoading = false
    state.patients = patients
    state.patientsDict = patientsDict
    state.enrolledPatients = enrolledPatients
    state.unenrolledPatients = unenrolledPatients
    state.enrolledPatientsZips = enrolledPatientsZips
    this.dispatch('inbox/refreshOrgNotifications', null, { root: true })
  },
  _setPatientsLoading(state, bool) {
    state.patientsLoading = bool
  },
  _setSettings(state, settings) {
    state.settings = settings
  },
  _setSettingsLoading(state, bool) {
    if (state.settingsLoading !== bool) state.settingsLoading = bool
  },
  _setUnreadConvosCount(state, count) {
    state.unreadConvosCount = count
  },
  _setUsers(state, users) {
    const usersDict = {}
    for (const user of users) {
      prepUser(user)
      usersDict[user.id] = user
    }
    state.users = users
    state.usersDict = usersDict
  },
  _setUsersLoading(state, bool) {
    state.usersLoading = bool
  },
  _setProviderReviewNotifications(state, notifications) {
    state.providerReviewNotifications = notifications
  },
  _setCareTeamReviewNotifications(state, notifications) {
    state.careTeamReviewNotifications = notifications
  },
  _setMentions(state, notifications) {
    state.mentions = notifications
  },
  _setTicklers(state, notifications) {
    state.ticklers = notifications
  },

  replacePatient(state, { id, patient }) {
    const patients = state.patients
    const i = patients.findIndex(p => p.id === id)
    if (i === -1) return
    prepPat(patient)
    patients[i] = patient
    state.patients = [] // try to trigger reactivity by settings patients to [], then setting it back?
    this.commit('org/_setPatients', patients)
  },
}
