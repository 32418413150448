<template>
  <v-navigation-drawer
    app
    fixed
    mini-variant-width="60"
    :width="600"
    :mini-variant="!this.$route || !this.$route.meta || !this.$route.meta.expandedDrawer"
    color="white"
    mobile-breakpoint="0"
    permanent
    touchless
    stateless
    @transitionend="transitioning = false"
  >
    <v-row style="overscroll-behavior: contain" no-gutters class="fill-height flex-nowrap">
      <v-navigation-drawer fixed floating dark color="#001426" mini-variant mini-variant-width="60" permanent stateless>
        <v-list-item @click="navigateHome()">
          <v-list-item-icon>
            <v-img :src="require('@/assets/Arrowhealth-bug-RGB.png')"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list nav dense>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingDashboard" :to="{ name: 'Inbox' }">
                <v-list-item-icon>
                  <v-icon>mdi-view-dashboard</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Inbox</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>Inbox</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingMessaging" :to="{ name: 'Conversations' }">
                <v-list-item-icon>
                  <v-badge :value="unreadConvosCount" :content="unreadConvosCount" color="primary" overlap>
                    <v-icon>mdi-message-reply-text</v-icon>
                  </v-badge>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Conversations</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>Conversations</span>
          </v-tooltip>
          <app-drawer-user-navigation-list v-if="false" />
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingAccount" :to="accountRoute">
                <v-list-item-icon>
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            {{ user.firstName }} {{ user.lastName }}
          </v-tooltip>
          <v-fade-transition>
            <v-tooltip v-if="org" right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingOrg" exact :to="orgRoute">
                  <v-list-item-icon>
                    <v-icon>mdi-domain</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              {{ org.name }}
            </v-tooltip>
          </v-fade-transition>
          <v-fade-transition>
            <v-tooltip v-if="org" right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingEnrollment" :to="enrollmentRoute">
                  <v-list-item-icon>
                    <v-icon class="rotate">mdi-account-plus</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Enrollment</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              Enrollment
            </v-tooltip>
          </v-fade-transition>
          <v-fade-transition>
            <v-tooltip v-if="org" right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingDeviceManagement" :to="deviceRoute">
                  <v-list-item-icon>
                    <v-icon class="rotate">mdi-devices</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Devices</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              Devices
            </v-tooltip>
          </v-fade-transition>
          <v-fade-transition>
            <v-tooltip v-if="org" right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" @click="openNewBillingPlatform()" v-model="isDisplayingReports">
                  <v-list-item-icon>
                    <v-icon>mdi-google-spreadsheet</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Reports</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              Go to reports
            </v-tooltip>
          </v-fade-transition>
        </v-list>
        <template v-slot:append>
          <v-tooltip v-if="org" right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" @click="$emit('turnOnEnhanced')" v-model="isDisplayingReports">
                <v-list-item-icon>
                  <v-icon>mdi-chart-donut</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Enhanced View</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            Toggle Enhanced View
          </v-tooltip>
        </template>
      </v-navigation-drawer>

      <v-col
        style="
          overflow-y: auto;
          overflow-x: hidden;
          max-width: 310px;
          background-color: #f5f8fa;
          border-right: 1px solid #d7d8dc;
          padding-left: 60px;
        "
      >
        <v-card height="63" class="flex" flat tile color="transparent">
          <v-row align="center" no-gutters class="fill-height">
            <v-select
              v-if="orgSwitcherOptions.length > 0"
              style="font-weight: bold"
              hide-details
              solo
              flat
              background-color="transparent"
              placeholder="ORG SWITCHER"
              v-model="orgSwitcherSelection"
              :items="orgSwitcherOptions"
              @change="switchOrg"
              return-object
            >
              <template v-slot:selection="{ item }">
                <span>{{ item.name }}</span>
              </template>
              <template v-slot:item="{ item }">
                <span>{{ item.name }}</span>
              </template>

              <template v-slot:append>
                <v-chip class="px-2" x-small color="primary">{{ orgNotificationTotal }}</v-chip>
              </template>
            </v-select>
          </v-row>
        </v-card>
        <v-list dense nav>
          <v-subheader>PATIENT POPULATION</v-subheader>
          <inbox-section
            :selected="selectedCategory === index && mode === 3"
            @clicked=";(selectedCategory = index), (mode = 3), navigateHome()"
            :cat="cat"
            v-for="(cat, index) in specialtyArrayToUse"
            :key="index + 'cat3'"
            :dark="cat.dark"
          />
          <v-subheader>THRESHOLD BREACHES</v-subheader>
          <inbox-section
            :selected="selectedCategory === index && mode === 0"
            @clicked=";(selectedCategory = index), (mode = 0), navigateHome()"
            :cat="cat"
            v-for="(cat, index) in alertArray"
            :key="index + 'cat0'"
            :dark="cat.dark"
          />
          <v-subheader>PATIENT ENGAGEMENT</v-subheader>
          <inbox-section
            :selected="selectedCategory === index && mode === 1"
            @clicked=";(selectedCategory = index), (mode = 1), navigateHome()"
            :cat="cat"
            v-for="(cat, index) in arrayToUse"
            :key="index + 'cat1'"
            :dark="cat.dark"
          />
          <v-subheader> HEALTH COACHING </v-subheader>
          <inbox-section
            :selected="selectedCategory === index && mode === 2"
            @clicked=";(selectedCategory = index), (mode = 2), navigateHome()"
            :cat="cat"
            v-for="(cat, index) in notesArrayToUse"
            :key="index + 'cat2'"
            :dark="cat.dark"
          />
        </v-list>
      </v-col>
      <v-col style="overflow-y: auto; overflow-x: hidden; background-color: #f5f6fa">
        <v-card height="64" class="flex" flat tile color="transparent">
          <v-row align="center" no-gutters class="fill-height px-3">
            <global-patient-search-simplified
              :focusOnShow="false"
              @blurred="searchFocused = false"
              :orgsDict="fullOrgsDict"
              @searchResultPatient="searchResultPatient"
            />

            <v-text-field
              dense
              hide-details
              solo
              flat
              prepend-inner-icon="mdi-magnify"
              placeholder="Patient Search"
              v-model="patientSearchText"
              clearable
              v-if="false"
            >
            </v-text-field>
          </v-row>
        </v-card>
        <v-slide-y-reverse-transition hide-on-leave>
          <div v-if="!patientsLoading">
            <inbox-toolbar
              v-if="patientList && patientList.length > 0 && !patientsLoading"
              :selectedPatients="selectedPatients"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-simple-checkbox
                    v-bind="attrs"
                    v-on="on"
                    :value="selectedPatients.length === patientList.length"
                    :indeterminate="selectedPatients.length > 0 && selectedPatients.length !== patientList.length"
                    @click="toggleSelectAll"
                    :ripple="false"
                    color="primary"
                  ></v-simple-checkbox>
                </template>
                Select All
              </v-tooltip>
            </inbox-toolbar>

            <v-lazy
              :options="{
                threshold: 0,
              }"
              min-height="60"
              v-for="(listPatient, index) in patientList"
              :key="index + 'pt'"
              :transition="null"
            >
              <inbox-patient-card
                :selected="selectedPatients.includes(listPatient)"
                @toggleSelected="toggleSelected(listPatient)"
                :patientRoute="$route.params.id"
                :listPatient="listPatient"
                @clicked="handleClick"
              />
            </v-lazy>

            <empty-page
              :title="selectedListTitle"
              :detail="'Looks like you\'re all caught up here.'"
              v-if="!patientList || patientList.length === 0"
            />
          </div>
        </v-slide-y-reverse-transition>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>
<style>
.v-card--link:before {
  background: none;
}
</style>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import InboxSection from '@/components/rpminbox/InboxSection.vue'
import InboxPatientCard from '@/components/rpminbox/InboxPatientCard.vue'
import InboxToolbar from '@/components/rpminbox/InboxToolbar.vue'
import { getOrgs } from '@/shared-ui/store/org'
import EmptyPage from '@/components/rpminbox/EmptyPage.vue'
import GlobalPatientSearchSimplified from '@/components/rpminbox/GlobalPatientSearchSimplified.vue'

export default {
  components: {
    InboxSection,
    InboxPatientCard,
    InboxToolbar,
    EmptyPage,
    GlobalPatientSearchSimplified,
  },
  props: {
    enhancedPopulationView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCategory: 0,
      mode: 0,
      patientSearchText: null,
      selectedPatients: [],
      fullOrgsDict: {},
      orgSwitcherSelection: null,
      transitioning: false,
      searchFocused: false,
    }
  },
  watch: {
    // $route: {
    //   handler: function (ti, tb) {
    //     let currentDrawerOpen = tb && tb.meta && tb.meta.expandedDrawer === true
    //     let destinationDrawerOpen = ti && ti.meta && ti.meta.expandedDrawer === true

    //     if (currentDrawerOpen !== destinationDrawerOpen) {
    //       console.log('this thinls that there is a transition from open to closed')
    //       this.transitioning = true
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    // transitioning: {
    //   handler: function (val) {
    //     console.log('transition has changed', val)
    //     this.$emit('transitioning', val)
    //   },

    //   deep: true,
    //   immediate: true,
    // },

    // enrolledPatients: {
    //   handler: async function (val, oldVal) {
    //     if (val.length > 0 && (!oldVal || val.length !== oldVal.length)) {
    //       console.log('enrolled changed')
    //       val.forEach(async patient => {
    //         await this.convertLegacyReviewNotesToFirebaseReviewNotes(patient)
    //       })
    //     }
    //   },

    //   deep: true,
    //   immediate: true,
    // },
    patientList(val) {
      if (
        this.selectedListTitle === 'Care Team Review' ||
        this.selectedListTitle === 'Provider Review' ||
        this.selectedListTitle === 'Mentions'
      ) {
        this.setPatientList({ title: this.selectedListTitle, array: this.enrolledPatients })
      } else {
        this.setPatientList({ title: this.selectedListTitle, array: val })
      }
    },
    orgSwitcherOptions() {
      this.orgSwitcherSelection = this.orgSwitcherOptions.find(opt => {
        return opt.id === this.selectedOrgId
      })
      this.orgSwitcherSelection = this.orgSwitcherSelection || this.orgSwitcherOptions[0]
      this.loadOrg(this.orgSwitcherSelection.id)
    },
    orgNotificationDictionary(val) {
      this.setOrgNotifications(val)
    },
  },
  methods: {
    ...mapActions('org', ['loadOrg']),
    ...mapMutations('auth', ['setSelectedOrgId']),
    ...mapMutations('inbox', ['setPatientList', 'setOrgNotifications']),
    ...mapActions('patient', ['convertLegacyReviewNotesToFirebaseReviewNotes']),
    legacyProviderReviewNoteForDisplay(patientObject) {
      if (patientObject.rpm.notes.length > 0 && patientObject.tags.includes('Provider Review')) {
        let latestNote = patientObject.rpm.notes[0]
        let displayObject = { text: 'Note', author: 'Author', date: 'date' }

        if (latestNote.comments.length > 0) {
          latestNote = latestNote.comments[0]
        }
        if (this.orgUserDictionary[latestNote.userId]) {
          displayObject.author = this.orgUserDictionary[latestNote.userId].name
        }
        displayObject.text = latestNote.text
        displayObject.date = Date.parse(latestNote.ts)
        return displayObject
      }

      return null
    },
    legacyCareTeamNoteForDisplay(patientObject) {
      if (patientObject.rpm.notes.length > 0 && patientObject.tags.includes('Care Navigator Review')) {
        let latestNote = patientObject.rpm.notes[0]
        let displayObject = { text: 'Note', author: 'Author', date: 'date' }

        if (latestNote.comments.length > 0) {
          latestNote = latestNote.comments[0]
        }
        if (this.orgUserDictionary[latestNote.userId]) {
          displayObject.author = this.orgUserDictionary[latestNote.userId].name
        }
        displayObject.text = latestNote.text
        displayObject.date = Date.parse(latestNote.ts)
        return displayObject
      }

      return null
    },
    toggleEnhanced() {
      this.enhancedPopulationView = !this.enhancedPopulationView

      if (this.enhancedPopulationView) {
        localStorage['_tryEnhancedView'] = true
      } else {
        localStorage.removeItem('_tryEnhancedView')
      }
    },

    searchResultPatient(patient) {
      if (this.org.id === patient.org.id) {
        this.selectedCategory = 2
        this.mode = 3
        this.$router.push({ name: 'PatientFeed', params: { id: patient.id } })
      } else {
        this.orgSwitcherSelection = this.orgSwitcherOptions.find(opt => {
          return opt.id === patient.org.id
        })
        this.setPatientList({ title: this.selectedListTitle, array: [] })
        this.setOrgNotifications(null)
        this.setSelectedOrgId(this.orgSwitcherSelection.id)

        this.loadOrg(this.orgSwitcherSelection.id).then(() => {
          this.selectedCategory = 2
          this.mode = 3
          this.$router.push({ name: 'PatientFeed', params: { id: patient.id } })
        })
      }
    },
    generateNoteForDisplay(patientObject, notification) {
      if (patientObject.rpm.notes.length > 0) {
        let timestampOfCorrectNote = notification.ts
        let latestNote = patientObject.rpm.notes[0]

        if (latestNote.ts !== timestampOfCorrectNote) {
          patientObject.rpm.notes.forEach(note => {
            if (note.ts === timestampOfCorrectNote) {
              latestNote = note
            }
          })
        }

        if (notification.comment) {
          if (latestNote.comments.length > 0) {
            latestNote = latestNote.comments[0]
          }
        }
        let displayObject = { text: 'Note', author: 'Author', date: 'date' }

        if (this.orgUserDictionary[latestNote.userId]) {
          displayObject.author = this.orgUserDictionary[latestNote.userId].name
        }
        displayObject.text = latestNote.text
        displayObject.date = notification.notificationTimestamp
        return displayObject
      }

      return null
    },
    openNewBillingPlatform() {
      this.child = window.open('https://partners.novorpm.com', 'null', null)
    },
    switchOrg() {
      this.navigateHome()
      this.setPatientList({ title: this.selectedListTitle, array: [] })
      this.setOrgNotifications(null)
      this.setSelectedOrgId(this.orgSwitcherSelection.id)
      this.loadOrg(this.orgSwitcherSelection.id)
    },
    toggleSelectAll() {
      if (this.selectedPatients.length === this.patientList.length) {
        this.selectedPatients = []
      } else {
        this.selectedPatients = this.patientList
      }
    },
    toggleSelected(patient) {
      if (this.selectedPatients.includes(patient)) {
        this.selectedPatients = this.selectedPatients.filter(e => e !== patient)
      } else {
        this.selectedPatients.push(patient)
      }
    },
    navigateHome() {
      this.selectedPatients = []
      this.patientSearchText = null
      if (this.$route.name !== 'Inbox') {
        this.$router.push({ name: 'Inbox' })
      }
    },
    handleClick(patient) {
      if (this.$route.params.id === patient.id) {
        this.$router.push({ name: 'Inbox' })
      } else {
        this.$router.push({ name: 'PatientFeed', params: { id: patient.id } })
      }
    },
  },
  computed: {
    ...mapState('auth', ['user', 'selectedOrgId']),
    ...mapState('org', [
      'org',
      'patientsLoading',
      'enrolledPatients',
      'unreadConvosCount',
      'unenrolledPatients',
      'users',
    ]),
    ...mapState('org', ['providerReviewNotifications', 'careTeamReviewNotifications', 'mentions']),
    ...mapState('patient', ['patient']),
    orgUserDictionary() {
      const dict = {}
      if (this.users && this.users.length > 0) {
        this.users.forEach(user => {
          dict[user.id] = { name: 'Unknown', initials: 'UK' }
          if (user.firstName && user.lastName) {
            dict[user.id].name = user.firstName + ' ' + user.lastName
            dict[user.id].initials = user.firstName.charAt(0) + user.lastName.charAt(0)
          }
        })
      }
      return dict
    },
    expandedDrawer() {
      if ('Inbox' === this.$route.name || 'PatientFeed' === this.$route.name) {
        return true
      }
      return false
    },
    isDisplayingReports() {
      return 'Reports' === this.$route.name
    },
    isDisplayingAccount() {
      return 'Account' === this.$route.name
    },
    isDisplayingDevices() {
      return 'Enrollment' === this.$route.name
    },
    isDisplayingEnrollment() {
      return 'Device Management' === this.$route.name
    },
    isDisplayingOrg() {
      return 'Organization' === this.$route.name
    },
    accountRoute() {
      return { name: 'Account', params: { id: this.user.id } }
    },
    orgRoute() {
      return { name: 'Organization', params: { id: this.org.id } }
    },
    enrollmentRoute() {
      return { name: 'Enrollment', params: { id: this.org.id } }
    },
    deviceRoute() {
      return { name: 'Device Management', params: { id: this.org.id } }
    },
    isDisplayingAlerts() {
      return 'Alerts' === this.$route.name
    },
    isDisplayingInbox() {
      return 'Inbox' === this.$route.name
    },
    isDisplayingActivity() {
      return 'Engagement' === this.$route.name
    },
    isDisplayingDeviceManagement() {
      return 'Device Management' === this.$route.name
    },
    isDisplayingDashboard() {
      return 'Inbox' === this.$route.name
    },
    isDisplayingMessaging() {
      return 'Messaging' === this.$route.name
    },
    isDisplayingConversations() {
      return 'Conversations' === this.$route.name
    },
    notesForMentions() {
      let notifications = []
      if (this.enrolledPatients.length > 0) {
        this.mentions.forEach(notification => {
          console.log(notification)
          let patient = this.enrolledPatients.find(pt => pt.id === notification.patient)
          if (patient) {
            let notificationObject = {}
            notificationObject.patient = patient

            notificationObject.firstName = patient.firstName
            notificationObject.lastName = patient.lastName
            notificationObject.org = { patientId: patient.org.patientId }
            notificationObject.notificationTimestamp = notification.notificationTimestamp
            // get the text of the note

            notificationObject.noteForDisplay = this.generateNoteForDisplay(patient, notification)
            notificationObject.firebaseNotification = notification

            notifications.push(notificationObject)
          }
        })
      }

      return notifications
    },
    notesForProviderReview() {
      let notifications = []
      if (this.enrolledPatients.length > 0) {
        this.providerReviewNotifications.forEach(notification => {
          let patient = this.enrolledPatients.find(pt => pt.id === notification.patient)
          if (patient) {
            let notificationObject = {}
            notificationObject.patient = patient

            notificationObject.firstName = patient.firstName
            notificationObject.lastName = patient.lastName
            notificationObject.org = { patientId: patient.org.patientId }
            notificationObject.notificationTimestamp = notification.notificationTimestamp
            // get the text of the note

            notificationObject.noteForDisplay = this.generateNoteForDisplay(patient, notification)
            notificationObject.firebaseNotification = notification

            notifications.push(notificationObject)
          }
        })
      }
      return notifications
    },

    notesForCareTeamReview() {
      let notifications = []
      if (this.enrolledPatients.length > 0) {
        this.careTeamReviewNotifications.forEach(notification => {
          let patient = this.enrolledPatients.find(pt => pt.id === notification.patient)

          let notificationObject = {}
          notificationObject.patient = patient

          notificationObject.firstName = patient.firstName
          notificationObject.lastName = patient.lastName
          notificationObject.org = { patientId: patient.org.patientId }
          notificationObject.notificationTimestamp = notification.notificationTimestamp
          // get the text of the note

          notificationObject.noteForDisplay = this.generateNoteForDisplay(patient, notification)
          notificationObject.firebaseNotification = notification

          notifications.push(notificationObject)
        })
      }
      return notifications
    },
    newWithin7Days() {
      return this.enrolledPatients.filter(function (e) {
        const thirtyDays = new Date()
        thirtyDays.setDate(thirtyDays.getDate() - 90)
        thirtyDays.setHours(0, 0, 0, 0)

        return (
          e.rpm &&
          e.rpm.enrollment &&
          e.rpm.enrollment.start &&
          Date.parse(e.rpm.enrollment.start) > thirtyDays.getTime() &&
          !e.tags.includes('welcomed')
        )
      })
    },
    careTeamStarred() {
      return this.enrolledPatients.filter(function (e) {
        return e.tags && e.tags.includes('Care Team Starred')
      })
    },
    providerReview() {
      return this.notesForProviderReview
    },
    navigatorReview() {
      return this.notesForCareTeamReview
    },
    mentionsReview() {
      return this.notesForMentions
    },
    zeroTime() {
      return this.enrolledPatients.filter(function (e) {
        //listPatient.rpm.qhpTime.millisecondsThisPeriod
        return !e.rpm.qhpTime || !e.rpm.qhpTime.millisecondsThisPeriod || e.rpm.qhpTime.millisecondsThisPeriod === 0
      })
    },
    under20() {
      return this.enrolledPatients
        .filter(function (e) {
          return (
            e.rpm.qhpTime &&
            e.rpm.qhpTime.millisecondsThisPeriod &&
            e.rpm.qhpTime.millisecondsThisPeriod > 0 &&
            e.rpm.qhpTime.millisecondsThisPeriod < 1200000
          )
        })
        .sort((a, b) => b.rpm.qhpTime.millisecondsThisPeriod - a.rpm.qhpTime.millisecondsThisPeriod)
    },
    under40() {
      return this.enrolledPatients
        .filter(function (e) {
          return (
            e.rpm.qhpTime &&
            e.rpm.qhpTime.millisecondsThisPeriod &&
            e.rpm.qhpTime.millisecondsThisPeriod >= 1200000 &&
            e.rpm.qhpTime.millisecondsThisPeriod < 2400000
          )
        })
        .sort((a, b) => b.rpm.qhpTime.millisecondsThisPeriod - a.rpm.qhpTime.millisecondsThisPeriod)
    },
    moreThan40() {
      return this.enrolledPatients
        .filter(function (e) {
          return (
            e.rpm.qhpTime && e.rpm.qhpTime.millisecondsThisPeriod && e.rpm.qhpTime.millisecondsThisPeriod >= 2400000
          )
        })
        .sort((a, b) => b.rpm.qhpTime.millisecondsThisPeriod - a.rpm.qhpTime.millisecondsThisPeriod)
    },
    archivedPatients() {
      return this.unenrolledPatients
    },
    specialtyArrayToUse() {
      return [
        {
          order: 0,
          count: this.newWithin7Days.length,
          color: 'primary',
          title: 'New Patients',
          list: this.newWithin7Days,
          fullTitle: 'New Patients',
          icon: 'mdi-account-plus-outline',
          filledIcon: 'mdi-account-plus',
          total: Math.floor((this.newWithin7Days.length / this.enrolledPatients.length) * 100),
          description: 'Patients added recently without a note in their chart.',
        },
        {
          order: 0,
          count: this.careTeamStarred.length,
          color: 'grey lighten-2',
          title: 'Starred',
          list: this.careTeamStarred,
          fullTitle: 'Starred',
          icon: 'mdi-star-outline',
          filledIcon: 'mdi-star',
          total: Math.floor((this.careTeamStarred.length / this.enrolledPatients.length) * 100),
          description: 'Patients with stars!',
        },
        {
          order: 0,
          count: this.enrolledPatients.length,
          color: 'grey lighten-2',
          title: 'Enrolled Patients',
          list: this.enrolledPatients,
          fullTitle: 'Enrolled Patients',
          icon: 'mdi-account-multiple-outline',
          filledIcon: 'mdi-account-multiple',
          total: Math.floor((this.enrolledPatients.length / this.enrolledPatients.length) * 100),
          description: 'Entire enrolled patient population',
        },
        {
          order: 0,
          count: this.archivedPatients.length,
          color: 'grey lighten-2',
          title: 'Archived Patients',
          list: this.archivedPatients,
          fullTitle: 'Archived Patients',
          icon: 'mdi-account-multiple-outline',
          filledIcon: 'mdi-account-multiple',
          total: Math.floor((this.archivedPatients.length / this.archivedPatients.length) * 100),
          description: 'Patients who are not currently enrolled',
        },
      ]
    },
    notesArrayToUse() {
      return [
        {
          order: 0,
          count: this.providerReview.length,
          color: 'grey lighten-2',
          title: 'Provider Review',
          list: this.providerReview,
          fullTitle: 'Provider Review',
          icon: 'mdi-flag-outline',
          filledIcon: 'mdi-flag',
          total: Math.floor((this.providerReview.length / this.enrolledPatients.length) * 100),
          description: 'Patient who are flagged for provider review',
          listForSetting: this.enrolledPatients,
        },
        {
          order: 0,
          count: this.navigatorReview.length,
          color: '#1A76F2',
          dark: true,
          title: 'Care Team Review',
          list: this.navigatorReview,
          fullTitle: 'Care Team Review',
          icon: 'mdi-flag-outline',
          filledIcon: 'mdi-flag',
          total: Math.floor((this.navigatorReview.length / this.enrolledPatients.length) * 100),
          description: 'Patients who are flagged for careteam review',
          listForSetting: this.enrolledPatients,
        },
        {
          order: 0,
          count: this.mentionsReview.length,
          color: '#1A76F2',
          dark: true,
          title: 'Mentions',
          list: this.mentionsReview,
          fullTitle: 'Care Team Review',
          icon: 'mdi-at',
          filledIcon: 'mdi-at',
          total: Math.floor((this.mentionsReview.length / this.enrolledPatients.length) * 100),
          description: "Patient notes where you've been tagged",

          listForSetting: this.enrolledPatients,
        },
        {
          order: 0,
          count: this.zeroTime.length,
          color: 'grey lighten-2',
          dark: false,
          title: '0 min',
          list: this.zeroTime,
          fullTitle: '0 min',
          icon: 'mdi-clock-outline',
          filledIcon: 'mdi-clock',
          total: Math.floor((this.zeroTime.length / this.enrolledPatients.length) * 100),
          description: 'Patients who have no accumulated QHP time this month',
        },
        {
          order: 0,
          count: this.under20.length,
          color: 'grey lighten-2',
          dark: false,
          title: 'Under 20 min',
          list: this.under20,
          fullTitle: 'Under 20 min',
          icon: 'mdi-clock-outline',
          filledIcon: 'mdi-clock',
          total: Math.floor((this.under20.length / this.enrolledPatients.length) * 100),
          description: 'Patients who have fewer than 20 minutes of accumulated QHP time this month',
        },
        {
          order: 0,
          count: this.under40.length,
          color: 'grey lighten-2',
          dark: false,
          title: 'Under 40 min',
          list: this.under40,
          fullTitle: 'Under 40 min',
          icon: 'mdi-clock-outline',
          filledIcon: 'mdi-clock',
          total: Math.floor((this.under40.length / this.enrolledPatients.length) * 100),
          description: 'Patients who have fewer than 40 minutes of accumulated QHP time this month but more than 20',
        },
        {
          order: 0,
          count: this.moreThan40.length,
          color: 'grey lighten-2',
          dark: false,
          title: '40+ min',
          list: this.moreThan40,
          fullTitle: '40+ min',
          icon: 'mdi-clock-outline',
          filledIcon: 'mdi-clock',
          total: Math.floor((this.moreThan40.length / this.enrolledPatients.length) * 100),
          description: 'Patients who have more than 40 minutes of accumulated QHP time this month',
        },
      ]
    },
    unseenAlerts() {
      return this.enrolledPatients.filter(function (e) {
        return e.unseenAlert
      })
    },
    generalBreaches() {
      return this.enrolledPatients.filter(function (e) {
        return e.alert && !e.unseenAlert
      })
    },
    alertArray() {
      return [
        {
          order: 0,
          count: this.unseenAlerts.length,
          color: 'primary',
          title: 'New Alerts',
          dark: true,
          list: this.unseenAlerts,
          fullTitle: 'New Alerts',
          icon: 'mdi-alert-circle-outline',
          filledIcon: 'mdi-alert-circle',
          total: Math.floor((this.unseenAlerts.length / this.enrolledPatients.length) * 100),
          description: 'Patients who have recently breached a threshold and have not yet been acknowledged',
        },
        {
          order: 0,
          count: this.generalBreaches.length,
          color: 'grey lighten-2',
          title: 'Acknowledged Alerts',
          list: this.generalBreaches,
          fullTitle: 'Acknowledged Alerts',
          icon: 'mdi-alert-circle-check-outline',
          filledIcon: 'mdi-alert-circle-check',
          total: Math.floor((this.generalBreaches.length / this.enrolledPatients.length) * 100),
          description: 'All patients whose last reading breached a threshold and have been acknowledged',
        },
      ]
    },
    doingGreat() {
      return this.enrolledPatients.filter(function (e) {
        return !e.possibleOffTrack && !e.unenrollCandidate
      })
    },
    offTrack() {
      return this.enrolledPatients.filter(function (e) {
        return e.possibleOffTrack && !e.unenrollCandidate
      })
    },
    moderateRisk() {
      return this.offTrack.filter(function (e) {
        return e.perfectionNeeded <= 80
      })
    },
    severeRisk() {
      return this.offTrack.filter(function (e) {
        return e.perfectionNeeded > 80 && e.perfectionNeeded <= 100
      })
    },
    ineligible() {
      return this.offTrack.filter(function (e) {
        return e.perfectionNeeded > 100
      })
    },
    inactive() {
      return this.enrolledPatients.filter(function (e) {
        return e.unenrollCandidate
      })
    },
    arrayToUse() {
      let combinedCategories = [
        {
          order: 0,
          count: this.doingGreat.length,
          color: 'green lighten-3',
          title: 'On Track',
          list: this.doingGreat,
          fullTitle: 'On Track',
          icon: 'mdi-thumb-up-outline',
          filledIcon: 'mdi-thumb-up',
          total: Math.floor((this.doingGreat.length / this.enrolledPatients.length) * 100),
          description:
            'Patients who are currently projected to complete 16 days of readings within their billing period',
        },
        {
          order: 1,
          count: this.moderateRisk.length,
          color: 'red lighten-3',
          title: 'Moderate Risk',
          list: this.moderateRisk,
          fullTitle: 'Moderate Risk',
          icon: 'mdi-alert-circle-outline',
          filledIcon: 'mdi-alert-circle',
          total: Math.floor((this.moderateRisk.length / this.enrolledPatients.length) * 100),
          description:
            'Patients that need to take a reading between 50% and 80% of the days remaining in their billing period to meet a minimum of 16 days of readings.',
        },
        {
          order: 2,
          count: this.severeRisk.length,
          color: 'red lighten-2',
          title: 'Severe Risk',
          list: this.severeRisk,
          fullTitle: 'Severe Risk',
          icon: 'mdi-alert-circle-outline',
          filledIcon: 'mdi-alert-circle',
          total: Math.floor((this.severeRisk.length / this.enrolledPatients.length) * 100),
          description:
            'Patients that need to take a reading between 80% and 100% of the days remaining in their billing period to meet a minimum of 16 days of readings.',
        },
        {
          order: 3,
          count: this.ineligible.length,
          color: 'grey lighten-1',
          title: 'Ineligible',
          list: this.ineligible,
          fullTitle: 'Ineligible',
          icon: 'mdi-alert-octagon-outline',
          filledIcon: 'mdi-alert-octagon',
          total: Math.floor((this.ineligible.length / this.enrolledPatients.length) * 100),
          description:
            'Patients who are projected to be unable to meet the minumum of 16 days of readings in the current billing period.',
        },
        {
          order: 4,
          count: this.inactive.length,
          color: 'grey darken-1',
          title: 'Inactive',
          dark: true,
          list: this.inactive,
          fullTitle: 'Inactive',
          icon: 'mdi-close-circle-outline',
          filledIcon: 'mdi-close-circle',
          total: Math.floor((this.inactive.length / this.enrolledPatients.length) * 100),
          description: 'Patients who have not taken readings within the last month.',
        },
      ]

      return combinedCategories
    },
    patientList() {
      let arrayForSorting = []
      if (this.mode === 0) {
        arrayForSorting = this.alertArray[this.selectedCategory].list
      }
      if (this.mode === 1) {
        arrayForSorting = this.arrayToUse[this.selectedCategory].list
      }
      if (this.mode === 2) {
        arrayForSorting = this.notesArrayToUse[this.selectedCategory].list
      }
      if (this.mode === 3) {
        arrayForSorting = this.specialtyArrayToUse[this.selectedCategory].list
      }

      if (this.patientSearchText !== null && this.patientSearchText !== '') {
        const rgx = new RegExp(this.patientSearchText, 'i')
        arrayForSorting = arrayForSorting.filter(function (e) {
          const toTest = [
            e.firstName,
            e.lastName,
            e.org?.patientId || '',
            e.displayBirthdate || '',
            e.fullName || '',
          ].join('|')
          return rgx.test(toTest)
        })
      }

      return arrayForSorting
    },

    selectedListTitle() {
      if (this.mode === 0) {
        return this.alertArray[this.selectedCategory].title
      }
      if (this.mode === 1) {
        return this.arrayToUse[this.selectedCategory].title
      }
      if (this.mode === 2) {
        return this.notesArrayToUse[this.selectedCategory].title
      }
      if (this.mode === 3) {
        return this.specialtyArrayToUse[this.selectedCategory].title
      }

      return 'Title'
    },
    orgNotificationTotal() {
      // this is a sum of the new patients, unseen alerts, and the care team review patients

      return this.newWithin7Days.length + this.unseenAlerts.length + this.navigatorReview.length
    },
    orgNotificationDictionary() {
      // org notifications should have three things in them.
      //new patient reminder
      // threshold breach
      // care team review
      // mentions
      let orgNotifications = {}
      this.newWithin7Days.forEach(patient => {
        if (!orgNotifications[patient.id]) {
          orgNotifications[patient.id] = []
        }
        let notification = {
          title: 'New Patient',
          text: patient.firstName + ' has been enrolled recently but may need initial outreach.',
          timestamp: Date.parse(patient.rpm.enrollment.start),
          type: 'newPatient',
        }
        orgNotifications[patient.id].push(notification)
      })

      this.unseenAlerts.forEach(patient => {
        if (!orgNotifications[patient.id]) {
          orgNotifications[patient.id] = []
        }

        let alertSentence = ''
        let alertsForClearing = []
        patient.rpm.newAlertsArray.forEach(alert => {
          const reading = patient.rpm.lastReadings[alert]
          alertsForClearing.push(reading.id)
          alertSentence =
            alertSentence + reading.longName + ' was ' + reading.displayValue + ' on ' + reading.readableDate + '. '
        })

        let notification = {
          title: 'Threshold Breach',
          text: patient.firstName + "'s " + alertSentence,
          timestamp: Date.parse(patient.rpm.enrollment.start),
          type: 'thresholdBreach',
          payload: alertsForClearing,
          notePrompt: patient.firstName + "'s " + alertSentence,
        }
        orgNotifications[patient.id].push(notification)
      })

      this.notesForCareTeamReview.forEach(notificationObject => {
        if (!orgNotifications[notificationObject.patient.id]) {
          orgNotifications[notificationObject.patient.id] = []
        }

        let truncatedText = ''

        if (notificationObject.noteForDisplay.text.length < 149) {
          truncatedText = notificationObject.noteForDisplay.text
        } else {
          truncatedText = notificationObject.noteForDisplay.text.slice(0, 150)
        }
        let notification = {
          title: 'Care Team Review',
          text: notificationObject.noteForDisplay.author + ': ' + truncatedText,
          timestamp: new Date(),
          type: 'careTeamReview',
          notificationForClearing: notificationObject.firebaseNotification,
        }
        orgNotifications[notificationObject.patient.id].push(notification)
      })

      if (this.org && this.org.id && this.user && this.user.firstName) {
        this.notesForMentions.forEach(notificationObject => {
          if (!orgNotifications[notificationObject.patient.id]) {
            orgNotifications[notificationObject.patient.id] = []
          }

          let truncatedText = ''

          if (notificationObject.noteForDisplay.text.length < 149) {
            truncatedText = notificationObject.noteForDisplay.text
          } else {
            truncatedText = notificationObject.noteForDisplay.text.slice(0, 150)
          }
          let notification = {
            title: this.user.firstName + ' ' + this.user.lastName,
            text: notificationObject.noteForDisplay.author + ': ' + truncatedText,
            timestamp: new Date(),
            type: '@mention',
            notificationForClearing: notificationObject.firebaseNotification,
          }
          orgNotifications[notificationObject.patient.id].push(notification)
        })
      }

      return orgNotifications
    },
    orgSwitcherOptions() {
      const options = []
      for (const o of this.user.orgs) {
        if (!this.fullOrgsDict[o.orgId]) continue
        options.push({
          name: this.fullOrgsDict[o.orgId].name,
          id: o.orgId,
          value: this.fullOrgsDict[o.orgId].name,
        })
      }
      options.sort((a, b) => a.name.localeCompare(b.name))
      return options
    },
  },
  async beforeCreate() {
    console.log('old app drawer')
    const fullOrgsDict = {}
    for (const org of await getOrgs()) {
      fullOrgsDict[org.id] = org
    }
    this.fullOrgsDict = fullOrgsDict
  },
}
</script>
