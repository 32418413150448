export default {
  _reset(state) {
    // user info
    state.user = null
    state.selectedOrgId = null
  },
  _setReady(state) {
    state.ready = true
  },
  _setUser(state, user) {
    user.orgs = user.orgs || []
    state.user = user
  },
  setSelectedOrgId(state, orgId) {
    if (state.selectedOrgId === orgId) return
    state.selectedOrgId = orgId
    localStorage[state.user.id+'_selectedOrgId'] = orgId
  },
  setDemoFeaturesToTrue(state) {
    state.demoFeatures = true
  },
}
