<template>
  <v-tooltip open-delay="1000" max-width="300" v-if="cat" color="grey darken-4" right>
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        :input-value="selected"
        color="primary"
        active-class="font-weight-bold"
        v-bind="attrs"
        v-on="on"
        @click="$emit('clicked')"
        style="font-weight:400"
      >
        <v-list-item-icon class="mr-2 ">
          <v-icon v-if="selected && cat.filledIcon" size="18">{{ cat.filledIcon }}</v-icon>
          <v-icon v-else size="18">{{ cat.icon }}</v-icon></v-list-item-icon
        >
        <v-list-item-content>
          <v-list-item-title>{{ cat.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-chip class="px-2" :dark="dark" :color="cat.color" x-small
            ><v-row no-gutters justify="center">{{ cat.count }}</v-row></v-chip
          >
        </v-list-item-action>
      </v-list-item>
    </template>
    <span>{{ cat.description }}</span>
  </v-tooltip>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    cat: {
      type: Object,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
})
</script>
